import { React, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  Divider,
  Chip,
  Stack,
  Button,
  IconButton,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";

import Admin_Body from "../components/Admin/Admin_body";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function Admin() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    if (open) setOpen(false);
    else setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const divStyle = {
    position: "relative",
  };

  const imgStyle = {
    position: "absolute",
    top: "-5px",
    right: 0,
    width: "77px",
    height: "107px",
    objectFit: "fill",
  };
  const containerStyle = {
    padding: "6px 25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Main
        open={open}
        sx={{
          background: "white",
          borderRadius: "30px",
          height: "auto",
          py: 0,
          px: 0,
          paddingTop: "5px",
        }}
      >
        <Box sx={{ borderTopRightRadius: "30px" }}>
          <Toolbar>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="174" height="55" viewBox="0 0 174 55" fill="none">
             </svg> */}
            <img
              src="./glogo.png"
              style={{ width: 200, marginTop: 18, marginLeft: 6 }}
            />
          </Toolbar>
          <div style={containerStyle}>
            <Typography
              variant="h7"
              noWrap
              sx={{ flexGrow: 1, fontWeight: 600 }}
              component="div"
            >
              {/* Admin Settings */}
            </Typography>
          </div>
        </Box>

        <Divider />

        <Admin_Body />
      </Main>

      {/* This drawer is for citations */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            borderRadius: "30px",
            height: "auto",
            marginTop: "16px",
            marginRight: 1,
          },
          top: "20px",
          zIndex: open ? 0 : -1,
        }}
        style={{ marginTop: "1% !important" }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: 1, paddingTop: "15px" }}
        >
          <Typography
            variant="h1"
            sx={{
              color: "#101828",
              fontWeight: 500,
              fontSize: 16,
            }}
          >
            Citations
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>

        <Divider />
      </Drawer>
    </Box>
  );
}
