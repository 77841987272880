import "./footer.css";
const Footer = (props) => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="new-topic">
          {/* <div className="button-base"> */}
          {/* <img className="circle-icon" alt="" src="circle.png" /> */}
          {/* <div className="newtopic_text">New Topic</div> */}
        </div>
      </div>
      <div className="msg-input">
        <div className="input-field-base">
          <div className="input-field-base">
            <div className="input">
              <div className="content">
                <input
                  className="text"
                  placeholder="Type your message here ..."
                  value={props.input_message}
                  onChange={props.handleInputChange}
                  onKeyDown={props.handleKeyDown}
                  onFocus={(event) => (event.target.style.outline = "none")}
                  onBlur={(event) => (event.target.style.outline = "")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="send-icon">
        <div className="button">
          {/* <div className="button-base1"> */}
          {/* <img className="send-03-icon" alt="" src="send-03.png" /> */}
          {/* </div> */}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Footer;
