// actions.js
export const ADD_USER_MESSAGE = "ADD_USER_MESSAGE";
export const ADD_BOT_RESPONSE = "ADD_BOT_RESPONSE";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES"; // New action type for clearing messages
export const SET_TEMPERATURE = "SET_TEMPERATURE";
export const SET_KNOWLEDGE_TYPE = "SET_KNOWLEDGE_TYPE";
export const SET_PROMPT_TITLE = "SET_PROMPT_TITLE";
export const SET_SYSTEM_MESSAGE = "SET_SYSTEM_MESSAGE";

export const SET_INPUT_MESSAGE = "SET_INPUT_MESSAGE";

export const SET_LANGUAGE = "SET_LANGUAGE";

export const SET_FULL_CONVERSATION_FROM_BACKEND =
  "SET_FULL_CONVERSATION_FROM_BACKEND";

export const SET_PAST_MESSAGES = "SET_PAST_MESSAGES";

export const SET_LOADING = "SET_LOADING";

export const SET_CURRENT_TAB = "SET_CURRENT_TAB";

export const SET_CITATIONS = "SET_CITATIONS";

export const SET_SHOW_CITATIONS = "SET_SHOW_CITATIONS";
export const SET_SHOW_DRAWER = "SET_SHOW_DRAWER";

export const SET_IS_ADMIN = "SET_IS_ADMIN";
export const SET_CONF = "SET_CONF";
export const SET_HISTORY = "SET_HISTORY";

export const setHistory = (value) => ({
  type: SET_HISTORY,
  payload: value,
});

export const setConf = (value) => ({
  type: SET_CONF,
  payload: value,
});

export const setIsAdmin = (value) => ({
  type: SET_IS_ADMIN,
  payload: value,
});

export const setShowCitations = (message) => ({
  type: SET_SHOW_CITATIONS,
  payload: message,
});

export const setShowDrawer = (value) => ({
  type: SET_SHOW_DRAWER,
  payload: value,
});

export const setCitations = (message) => ({
  type: SET_CITATIONS,
  payload: message,
});

export const addUserMessage = (message) => ({
  type: ADD_USER_MESSAGE,
  payload: message,
});

export const addBotResponse = (
  response,
  citationsPresent,
  citations,
  query_id
) => ({
  type: ADD_BOT_RESPONSE,
  payload: {
    query_id: query_id,
    text: response,
    citations_present: citationsPresent,
    citations: citations,
  },
});

export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
});

export const setTemeparature = (response) => ({
  type: SET_TEMPERATURE,
  payload: response,
});

export const setKnowledgeType = (response) => ({
  type: SET_KNOWLEDGE_TYPE,
  payload: response,
});

export const setLanguage = (response) => ({
  type: SET_LANGUAGE,
  payload: response,
});

export const setPromptTitle = (response) => ({
  type: SET_PROMPT_TITLE,
  payload: response,
});

export const setSystemMessage = (response) => ({
  type: SET_SYSTEM_MESSAGE,
  payload: response,
});

export const setInput_message = (response) => ({
  type: SET_INPUT_MESSAGE,
  payload: response,
});

export const setfull_conversation = (response) => ({
  type: SET_FULL_CONVERSATION_FROM_BACKEND,
  payload: response,
});

export const setPast_message_included = (response) => ({
  type: SET_PAST_MESSAGES,
  payload: response,
});

export const setLoading = (response) => ({
  type: SET_LOADING,
  payload: response,
});

export const setCurrentTab = (response) => ({
  type: SET_CURRENT_TAB,
  payload: response,
});
