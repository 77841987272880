// reducers.js
import {
  ADD_USER_MESSAGE,
  ADD_BOT_RESPONSE,
  CLEAR_MESSAGES,
  SET_TEMPERATURE,
  SET_KNOWLEDGE_TYPE,
  SET_PAST_MESSAGES,
  SET_LANGUAGE,
  SET_PROMPT_TITLE,
  SET_SYSTEM_MESSAGE,
  SET_INPUT_MESSAGE,
  SET_FULL_CONVERSATION_FROM_BACKEND,
  SET_LOADING,
  SET_CURRENT_TAB,
  SET_SHOW_CITATIONS,
  SET_CITATIONS,
  SET_SHOW_DRAWER,
  SET_IS_ADMIN,
  SET_CONF,
  SET_HISTORY,
} from "./actions";

const initialState = {
  input_message: "",
  messages: [
    {
      text: "Hi, How can I help you?",
      fromUser: false,
      citations_present: false,
      citations: [],
    },
  ],
  temperature: 0.7,
  past_messages_included: 10,
  system_message: "You are an AI assistant that helps people find information.",
  knowledge_type: "Global",
  language: "English",
  prompt_title_selected_for_global: "Default",
  voice_status: false,

  full_conversataion_from_backend: [],

  loading: false, // fetching response for chatbot,

  currentTab: "Chat",
  showCitations: false,
  citations: [],
  showDrawer: false,
  isAdmin: false,
  conf: {},
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONF:
      return {
        ...state,
        conf: { conf: action.payload },
      };

    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      };

    case SET_SHOW_DRAWER:
      return {
        ...state,
        showDrawer: action.payload,
      };

    case SET_SHOW_CITATIONS:
      return {
        ...state,
        showCitations: action.payload,
      };

    case SET_CITATIONS:
      return {
        ...state,
        citations: action.payload,
      };

    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };

    case ADD_USER_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, { text: action.payload, fromUser: true }],
      };
    // case ADD_BOT_RESPONSE:
    //   return {
    //     ...state,
    //     messages: [...state.messages, { text: action.payload, fromUser: false }],
    //   };

    case ADD_BOT_RESPONSE:
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            query_id: action.payload.query_id,
            text: action.payload.text,
            fromUser: false,
            citations_present: action.payload.citations_present,
            citations: action.payload.citations,
          },
        ],
      };

    case CLEAR_MESSAGES:
      return {
        ...state,
        messages: [
          {
            text: "Hi, How can I help you?",
            fromUser: false,
            citations_present: false,
            citations: [],
          },
        ],
        full_conversataion_from_backend: [],
      };
    case SET_TEMPERATURE:
      return {
        ...state,
        temperature: action.payload,
      };
    case SET_KNOWLEDGE_TYPE:
      return {
        ...state,
        knowledge_type: action.payload,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SET_PROMPT_TITLE:
      return {
        ...state,
        prompt_title_selected_for_global: action.payload,
      };
    case SET_SYSTEM_MESSAGE:
      return {
        ...state,
        system_message: action.payload,
      };
    case SET_INPUT_MESSAGE:
      return {
        ...state,
        input_message: action.payload,
      };

    case SET_FULL_CONVERSATION_FROM_BACKEND:
      return {
        ...state,
        full_conversataion_from_backend: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: Boolean(action.payload),
      };

    case SET_PAST_MESSAGES:
      return {
        ...state,
        past_messages_included: action.payload,
      };
    case SET_HISTORY:
      return {
        ...state,
        messages: action.payload,
      };

    default:
      return state;
  }
};

export default rootReducer;
