import { React, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  Divider,
  Chip,
  Stack,
  Button,
  IconButton,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";

// import Admin_Body from "../components/Admin/Admin_body";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function Analytic() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    if (open) setOpen(false);
    else setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const divStyle = {
    position: "relative",
  };

  const imgStyle = {
    position: "absolute",
    top: "-5px",
    right: 0,
    width: "77px",
    height: "107px",
    objectFit: "fill",
  };
  const containerStyle = {
    padding: "6px 25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Main
        open={open}
        sx={{
          background: "white",
          borderRadius: "30px",
          height: "716px",
          py: 0,
          px: 0,
          paddingTop: "5px",
          // overflow: "auto",
        }}
      >
        <Box sx={{ borderTopRightRadius: "30px" }}>
          <Toolbar>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="174" height="55" viewBox="0 0 174 55" fill="none">
             </svg> */}
            <img
              src="./glogo.png"
              style={{
                width: 200,
                marginTop: 18,
                marginLeft: 6,
                marginBottom: 15,
              }}
            />
          </Toolbar>
        </Box>
        <Divider />
        <div style={{ marginLeft: 10 }}>
          <div className="container-fluid mt-4 ">
            <div className="row col-12 ">
              <div className="col-6">
                {" "}
                <img
                  src="MicrosoftTeams-image (26).png"
                  style={{ width: 634, height: 284 }}
                />
              </div>
              <div className="col-6">
                <img src="picture4.png" style={{ width: 700, height: 284 }} />
              </div>
            </div>
            <div>
              <div className="row col-12  mt-4">
                <div className="col-6">
                  {" "}
                  <img
                    src="MicrosoftTeams-image (25).png"
                    style={{ width: 634, height: 284 }}
                  />
                </div>
                <div className="col-6">
                  <img src="graph4.png" style={{ width: 700, height: 284 }} />
                </div>
              </div>
            </div>
          </div>

          {/* <div
            style={{
              display: "flex",
              marginLeft: 30,
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <img src="statewise.png" style={{ width: 564, height: 284 }} />
            <img
              src="graph4.png"
              style={{ width: 700, height: 284, marginLeft: 60 }}
            />
          </div> */}

          {/* <div className="col-sm-">
              <img src="graph3.png" style={{ width: "100%", padding: 10 }} />
            </div> */}
          {/* <div className="col-sm-">
            <img src="graph3.png" style={{ width: "100%", padding: 10 }} />
          </div> */}

          {/* <div style={{ display: "flex", marginTop: "20px" }}></div>
          <div style={{ marginTop: "20px" }}></div> */}
        </div>
      </Main>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            borderRadius: "30px",
            height: "auto",
            marginTop: "16px",
            marginRight: 1,
          },
          top: "20px",
          zIndex: open ? 0 : -1,
        }}
        style={{ marginTop: "1% !important" }}
        variant="persistent"
        anchor="right"
        open={open}
      ></Drawer>
    </Box>
  );
}
