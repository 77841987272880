import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button, IconButton } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import {
  Delete,
  ReplayOutlined,
  SupervisedUserCircleSharp,
  WifiProtectedSetupSharp,
} from "@mui/icons-material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

const columns = [
  { id: "sno", label: "S.No", minWidth: 10 },
  { id: "file_name", label: "File name", minWidth: 100 },
  { id: "category", label: "Category", minWidth: 10 },
  { id: "uploaded_by", label: "Uploaded By", minWidth: 50 },
  { id: "status", label: "Status", minWidth: 50 },
  { id: "date", label: "Date", minWidth: 50 },
  { id: "time", label: "Time", minWidth: 50 },
  // { id: 'delete_btn', label: 'Delete', minWidth: 50 }
];

function createData(
  sno,
  file_name,
  category,
  uploaded_by,
  status,
  date,
  time,
  delete_btn
) {
  return {
    sno,
    file_name,
    category,
    uploaded_by,
    status,
    date,
    time,
    delete_btn,
  };
}

const rows = [
  createData(
    "1",
    "Filehsihsihwis.pdf",
    "safety rules",
    "Ramiya N",
    "Processing",
    "16-08-2023",
    "10.00:14 AM IST"
    // 'delete'
  ),
];

export default function Documents_status_table() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const Get_all_rows = () => {
    // Replace with your Flask API URL

    const BASE_URL = process.env.REACT_APP_API_BASE;
    const API_URL = process.env.REACT_APP_GET_DOC_STATUS_TABLE_API_URL;
    const URL = BASE_URL + API_URL;
    // console.log(URL);

    fetch(URL)
      .then((response) => response.json())
      .then((data) => {
        // Process the retrieved data
        // // console.log(data); // Print the data to the console
        // You can now manipulate the data as needed
        const row_temp = [];

        if (data.length > 0) {
          let serialNumber = 1;
          data.forEach((row) => {
            row_temp.push(
              createData(
                serialNumber.toString(),
                row.name,
                row.Category,
                row.UploadedBy,
                row.Status,
                row.Date,
                row.Time
                // 'delete'
              )
            );
            serialNumber++;
          });
        }

        setRows(row_temp);
        // console.log(row_temp);
      })
      .catch((error) => {
        // console.error("An error occurred:", error);
      });
  };
  React.useEffect(() => {
    Get_all_rows();
  }, []);

  const handle_Delete_Document = (row_id) => {
    // console.log("row_id", row_id);
    const matchingRow = rows.find((row) => row.sno === row_id.toString());
    // console.log(matchingRow);

    const requestBody = JSON.stringify({ data: matchingRow });

    const BASE_URL = process.env.REACT_APP_API_BASE;
    const API_URL = process.env.REACT_APP_DELETE_DOC_API_URL;
    const URL = BASE_URL + API_URL;

    fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: requestBody,
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.message); // Print the response message
        alert(data.message);
        Get_all_rows();
      })
      .catch((error) => {
        // console.error("An error occurred:", error);
      });
  };

  const StatusIcons = ({ value, file_name }) => {
    // console.log(file_name);
    if (value == "delete") {
      return (
        <IconButton onClick={() => handle_Delete_Document(file_name)}>
          <Delete sx={{ color: "grey" }} />
        </IconButton>
      );
    } else if (value == "Processing")
      return (
        <Button
          disableTouchRipple
          sx={{ textTransform: "none", cursor: "text", fontWeight: "600" }}
          startIcon={<WifiProtectedSetupSharp />}
        >
          Processing
        </Button>
      );
    else
      return (
        <Button
          disableTouchRipple
          sx={{
            textTransform: "none",
            cursor: "text",
            fontWeight: "600",
            color: "#7CC24D",
          }}
          startIcon={<CheckCircleOutlineRoundedIcon color="#7CC24D" />}
        >
          Completed
        </Button>
      );
  };

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Button
        style={{}}
        sx={{
          color: "#344054",
          // marginLeft: 1,
          // boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          // borderRadius: '8px',
          overflow: "hidden",
          fontSize: "13px",
          fontWeight: 600,
          // border: '0.50px #D0D5DD solid',
          textTransform: "none",
          bgcolor: "white",
        }}
        variant="text"
        startIcon={<CachedIcon />}
      >
        {/* Refresh */}
      </Button>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{}}>
              {columns.map((column) => (
                <TableCell
                  sx={{ bgcolor: "#486170", color: "white", py: 1 }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{ py: 1 }}
                        >
                          {value == "Processing" ||
                          value == "Completed" ||
                          value == "delete" ? (
                            <>
                              <StatusIcons value={value} file_name={row.sno} />
                            </>
                          ) : (
                            <>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
