import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TextField,
  IconButton,
  CircularProgress,
  Typography,
  formControlClasses,
} from "@mui/material";
import "./Uploader.css";
import {
  AddCircle,
  AddCircleOutline,
  PlusOneOutlined,
} from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";
import { Drawer } from "@mui/material";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Timeout } from "microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common/Timeout";

const product = [
  { label: "Accidental Damage Protection", value: "120" },
  { label: "Travel Insurance Policy", value: "250" },
];

const FileUploadComponent = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedCategorynew, setSelectedCategorynew] = useState("");
  const [states, setStates] = useState([]);
  const [selectestate, setSelectstate] = useState("");
  const [products, setProducts] = useState([]);
  const [selectproduct, setSelectproduct] = useState("");
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...newFiles]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files);

    const pdfFiles = newFiles.filter((file) =>
      file.name.toLowerCase().endsWith(".pdf")
    );

    if (pdfFiles.length < newFiles.length) {
      alert("Only PDF files are allowed.");
    }

    setSelectedFiles([...selectedFiles, ...pdfFiles]);
  };
  console.log(selectedFiles);

  const handleUpload = async () => {
    if (selectestate == "" && selectproduct == "") {
      toast.error("Select State and Product", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    } else if (selectestate !== "" && selectproduct == "") {
      toast.error("Select Product", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    } else if (selectestate == "" && selectproduct !== "") {
      toast.error("Select State", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    } else if (selectedFiles == "") {
      toast.error("Upload pdf!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    } else if (selectedFiles.length > 1) {
      toast.error("Only one PDF allowed", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    } else {
      if (selectedFiles.length > 0) {
        try {
          let formData = new FormData();
          selectedFiles.forEach((file, index) => {
            formData.append("pdf_file", file, file.name);
          });

          formData.append("state", selectestate);
          formData.append("product", selectproduct);
          formData.append("uploaded_by", sessionStorage.username);
          console.log(formData);

          setLoading(true);
          // alert('Upload success ! Find the file status in Document status section')
          // handleChange('event', 1)

          const BASE_URL = process.env.REACT_APP_API_BASE;
          const API_URL = process.env.REACT_APP_UPLOAD_FILE_API_URL;
          const URL = BASE_URL + API_URL;
          const response = await fetch(URL, {
            method: "POST",
            body: formData,
          });
          props.setParam(false);
          console.log(response);

          if (response.ok) {
            const data = await response.json();
            console.log("File uploaded successfully:", data);
            toast.success("File uploaded successfully", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 500,
            });
            setTimeout(() => {
              props.setOpen(false);
            }, 1000);

            props.setParam(true);
            console.log("test");
            setLoading(false);
            window.location.reload();
            if (
              data["message"] ==
              "Sorry the file(s) were skipped as they already exist."
            )
              alert(
                data["message"] +
                  "\nskipped files :\n" +
                  data["skipped_files"].toString()
              );
            else if (data["message"] == "success") {
              // alert(
              //   "Upload success ! Find the file status in Document status section"
              // );
              toast.success(
                "Upload success ! Find the file status in Document status section",
                {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 500,
                }
              );
              props.handleChange("event", 1);
              window.location.reload();
            }
            // Do something with the API response
          } else {
            console.error("Error uploading file:", response.statusText);
            toast.error("Error While  uploading file", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 500,
            });
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          toast.error("Error While  uploading file", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 500,
          });
        }
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDeleteFile = (index) => {
    const newFiles = selectedFiles.filter((file, i) => i !== index);
    setSelectedFiles(newFiles);
  };
  // const handleAddCategory = () => {
  // if (newCategory) {
  // setCategories([...categories, newCategory]);
  // setNewCategory("");
  // setSelectedCategory(newCategory);
  // }
  // };

  const GetStates = () => {
    const BASE_URL = process.env.REACT_APP_API_BASE;
    const API_URL = process.env.REACT_APP_GET_DOC_STATE_API_URL_UPLOAD;
    const URL = BASE_URL + API_URL;
    console.log(URL);
    fetch(URL)
      .then((response) => response.json())
      .then((data) => {
        setStates(data);
        console.log(data);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    GetStates();
  }, []);
  // added dropdown value

  // const Getproduct = () => {
  //   const BASE_URL = process.env.REACT_APP_API_BASE;
  //   const API_URL = process.env.REACT_APP_GET_DOC_CATEGORIES_API_URL;
  //   const URL = BASE_URL + API_URL;
  //   fetch(URL)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setProducts(data["data"]);
  //       console.log(data);
  //     })
  //     .catch((error) => console.log(error));
  // };
  // useEffect(() => {
  //   Getproduct();
  // }, []);

  return (
    <div>
      {loading ? (
        <>
          <Box
            sx={{ margin: "auto", width: "100%", textAlign: "center" }}
            style={{ paddingTop: "150px" }}
          >
            <CircularProgress
              size={68}
              sx={{
                color: "#2397D4",
              }}
            />
            <br />
            <Typography>Uploading in progress ..</Typography>
          </Box>
        </>
      ) : (
        <div>
          <Box sx={{ margin: "0px 30px", py: 0 }} style={{ padding: "0px" }}>
            <div
              style={{
                lineHeight: "",
                fontSize: "0.8rem",
                fontWeight: "600",
                marginBottom: "20px",
                marginTop: "80px",
              }}
            >
              {/* <span style={{ float: 'right', marginTop: '' }}>
                <input type='text'
                  style={{
                    padding: '5px 2px', marginRight: '5px'
                    , border: 'none', borderBottom: '1px solid #2397D4'
                  }}
                  label="Add New Category"
                  placeholder='Add new category ...'
                  value={newCategory}
                  onChange={(event) => setNewCategory(event.target.value)}

                />
                {newCategory.length > 0 ? <Button sx={{ padding: '2px 16px', bgcolor: '#2397D4' }} variant='contained' onClick={handleAddCategory}>
                  Add
                </Button> : <></>}
              </span> */}
            </div>
            <div style={{ display: "", width: 421, marginLeft: -13 }}>
              <Select
                sx={{
                  // border: '1px solid #ccc',
                  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                  borderRadius: "8px",
                  height: "44px",
                  paddingTop: "6px",

                  "& .MuiSelect-select": {
                    fontSize: 16,
                  },
                  "&::-webkit-scrollbar": {
                    width: 1500,
                  },

                  fontSize: "0.8rem",
                }}
                value={selectestate}
                onChange={(event) => setSelectstate(event.target.value)}
                displayEmpty
                fullWidth
                variant="outlined"
              >
                <MenuItem sx={{ fontSize: "0.8rem" }} value="" disabled>
                  Select State
                </MenuItem>
                {states?.map((state, index) => (
                  <MenuItem
                    sx={{ fontSize: "0.8rem" }}
                    key={index}
                    value={state}
                  >
                    {state}
                  </MenuItem>
                ))}
              </Select>

              {/* added dropdown */}
              <Select
                sx={{
                  // border: '1px solid #ccc',
                  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                  borderRadius: "8px",
                  fontSize: 16,
                  marginTop: "30px",
                  height: "44px",
                  paddingTop: "0px",
                  "& .MuiSelect-select": {
                    fontSize: 16,
                  },
                }}
                value={selectproduct}
                onChange={(event) => setSelectproduct(event.target.value)}
                displayEmpty
                fullWidth
                variant="outlined"
              >
                <MenuItem sx={{ fontSize: "0.8rem" }} value="" disabled>
                  Select Product
                </MenuItem>
                {product?.map((product, index) => (
                  <MenuItem
                    sx={{ fontSize: "0.8rem" }}
                    key={index}
                    value={product.value}
                  >
                    {product.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Box>

          <Box
            style={{
              marginTop: "10px",
              // marginLeft: "150px",
              // marginRight: "150px",
            }}
            sx={{ py: 0 }}
          >
            <div
              className="file-upload-container"
              style={{
                borderRadius: "5px",
                marginTop: "30px",
                height: "100px",
                border: "1px dashed #2397D4",
                textAlign: "center",
                padding: "5px 0px",
                width: "420px",
                marginLeft: "20px",
              }}
            >
              <div
                className="file-drop-area"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <div className="file-upload-text">
                  {/* <h2
                    style={{
                      color: "grey",
                      fontWeight: "500",
                      fontSize: "1.3rem",
                      lineHeight: 0,
                    }}
                  >
                    {" "}
                    <i className="fa fa-cloud-upload"></i>&nbsp; Drag &amp; Drop
                    files here
                  </h2> */}
                  <span style={{ marginTop: "0px" }}>
                    <label htmlFor="file-input" className="choose-file-label">
                      Choose Pdf File
                    </label>{" "}
                    to upload
                  </span>
                  <input
                    style={{ visibility: "hidden" }}
                    type="file"
                    id="file-input"
                    accept="application/pdf"
                    multiple
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>

            <></>

            <Box sx={{ px: 0 }}>
              {selectedFiles.length > 0 && (
                <div
                  className="selected-files"
                  style={{
                    height: "160px",
                    overflow: "auto",
                    marginTop: "15px",
                    marginBottom: "15px",
                    width: "420px",
                    marginLeft: "20px",
                  }}
                >
                  {/* <p>
                    Selected Files:
                    <span style={{ float: "right" }}>
                      <button onClick={handleUpload}>Upload</button>
                    </span>
                  </p> */}

                  <TableContainer>
                    <Table>
                      <TableHead sx={{ px: 0 }}>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "600", px: 0 }}>
                            Selected Files
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "600" }}>
                            Remove
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedFiles.map((file, index) => (
                          <TableRow key={index} sx={{ py: 0 }}>
                            <TableCell
                              sx={{ py: 0 }}
                              style={{ padding: "0px 2px" }}
                            >
                              {file.name}
                            </TableCell>
                            <TableCell sx={{ py: 0, px: 0 }} align="right">
                              <Button
                                // variant="contained"
                                // color="secondary"
                                startIcon={
                                  <DeleteIcon sx={{ color: "#2397D4" }} />
                                }
                                onClick={() => handleDeleteFile(index)}
                              ></Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}

              <Button
                variant="contained"
                size="small"
                sx={{
                  border: "1px dashed #2397D4",
                  width: "420px",
                  color: "white",
                  // marginLeft: "20px",
                  background: "#C21B17",
                  fontWeight: "600",
                  "&:hover": {
                    background: "#C21B17",
                  },
                }}
                style={{
                  // float:'right',
                  margin: "auto",
                  marginTop: "3%",
                  marginRight: "1%",
                  marginLeft: "20px",
                  bottom: "0px",
                }}
                onClick={handleUpload}
              >
                Upload
              </Button>
              <ToastContainer />

              <></>
            </Box>
          </Box>
        </div>
      )}
    </div>
  );
};

export default FileUploadComponent;
