import React, { useState } from "react";
import header from "../Styles/style.module.css";
import "./Tabs.css";
// import { Typography, Button, TextField } from "@material-ui/core";
// import { Refresh } from "@material-ui/icons";
// import { Search as SearchIcon } from "@material-ui/icons";
// import { Refresh as RefreshIcon } from "@material-ui/icons";
// import { Add as AddIcon } from "@material-ui/icons";
import Sidebar from "./Sidebar";
import { Drawer } from "@mui/material";
import MyTable from "./MyTable";
import Documents_status_table from "./Docs_status_table";

const MainContainer = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isSlideOpen, setIsSlideOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleToggleSlide = () => {
    setIsSlideOpen(!isSlideOpen);
  };
  const handleCloseSlide = () => {
    setIsSlideOpen(false);
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = () => {
    // Perform search functionality here
    // console.log("Search:", searchValue);
  };

  const handleRefresh = () => {
    // Perform refresh functionality here
    // console.log("Refresh");
  };

  const [param, setParam] = useState(true);
  // console.log(param, "pama");

  const [rows, setRows] = useState([]);
  const Get_all_rows = () => {
    // Replace with your Flask API URL
    const BASE_URL = process.env.REACT_APP_API_BASE;
    const API_URL = process.env.REACT_APP_GET_DOC_STATUS_TABLE_API_URL;
    const URL = BASE_URL + API_URL;
    // console.log(URL);
    fetch(URL)
      .then((response) => response.json())
      .then((data) => {
        // Process the retrieved data
        // console.log(data); // Print the data to the c
        // You can now manipulate the data as needed
        const row_temp = [];
        if (data.length > 0) {
          let serialNumber = 1;
          data.forEach((row) => {
            row_temp.push({
              id: serialNumber.toString(),
              slno: serialNumber.toString(),
              name: row.name,
              state: row.state,
              product: row.product,
              processing_status: row.processing_status,
            });
            serialNumber++;
          });
        }
        setRows(row_temp);
        // console.log(row_temp);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  React.useEffect(() => {
    Get_all_rows();
  }, [param]);

  const Load = () => {
    Get_all_rows();
    window.location.reload();
  };

  return (
    <div>
      <div className={header.box}>
        {/* <TextField
          className={header.search}
          variant="outlined"
          placeholder="Search"
          InputProps={{
            startAdornment: <SearchIcon />,
            style: { borderRadius: "5px", height: "25px" },
          }}
        /> */}
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
            width: "98%",
          }}
        >
          <button className="refresh" onClick={Load}>
            <i class="bi bi-arrow-clockwise"></i>
            Refresh
          </button>
          <button
            onClick={handleDrawerOpen}
            type="button"
            id="check"
            // onClick={""}
            className="upload"
          >
            {/* <AddIcon /> */}
            Upload
          </button>
        </div>
        <div
          style={{
            marginTop: 20,
            marginRight: 20,
            width: "98%",
            marginBottom: 20,
          }}
        >
          <MyTable rows={rows} setRows={setRows} />
          {/* <Documents_status_table /> */}
        </div>
        <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
          {/* Drawer content */}
          <div style={{ width: "450px" }}>
            <div className="content1">
              <div
                style={{
                  marginTop: "30px",
                  marginBttom: "-10px",
                  marginLeft: "187px",
                }}
              >
                <p style={{ fontWeight: 600 }}>Upload File</p>
              </div>
              <hr style={{ marginTop: "40px", marginBottom: "-60px" }} />

              <div style={{ marginLeft: 405 }}>
                <button
                  style={{
                    width: "10px",
                    height: "10px",
                    marginTop: "-11px",
                    marginBottom: "11px",
                  }}
                  className="btn btn-close"
                  aria-label="Close"
                  onClick={handleDrawerClose}
                ></button>
              </div>
              <hr style={{ color: "red" }} />
              <Sidebar open={open} setOpen={setOpen} setParam={setParam} />
            </div>
          </div>
        </Drawer>

        {/* <div className={`slide-container ${isSlideOpen ? "open" : ""}`}>
          <div className="content">
            <div className="content1">
              <div className={header.sideh}>Add new computer service</div>
              <button
                style={{ width: "20px", height: "20px" }}
                class="btn-close"
                aria-label="Close"
                onClick={handleCloseSlide}
              ></button>
            </div>
            <Sidebar />
            <button
              onClick={handleToggleSlide}
              type="button"
              className="btn btn-outline-secondary btn-sm "
              style={{
                borderRadius: "5px",
                marginTop: "0px",
                marginLeft: "260px",
              }}
            >
              Cancel
            </button>
            <button
              // onClick={handleToggleSlide}
              type="button"
              // onClick={""}
              className="btn btn-primary btn-sm "
              style={{
                borderRadius: "5px",
                marginTop: "0px",
                marginLeft: 10,
              }}
            >
              Create
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MainContainer;
