import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";

import { msalConfig } from "./authConfig";
import { BrowserRouter } from "react-router-dom";

import React, { useState, useEffect } from "react";
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */

msalConfig["auth"]["clientId"] = process.env.REACT_APP_CLIENT_ID;
msalConfig["auth"][
  "authority"
] = `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}/`;
const msalInstance = new PublicClientApplication(msalConfig);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// IF YOU WANT TO GET THE CREDENTIALS FROM BACKEND APP, USE THE BELOW CODE

// index.js

// import { msalConfig } from './authConfig';
// import { BrowserRouter } from 'react-router-dom';
// import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import { setConf, SET_CONF } from './redux/actions';

// const requestOptions = {
//   method: 'GET',
//   headers: {
//     'X-API-Key': process.env.REACT_APP_SERVER_AUTH // Replace <your_access_token> with the actual access token
//     // Add any other headers you need
//   },
// };
// function fetchAuthConfig() {
//   return fetch('http://localhost:3000/api/auth-config',requestOptions)
//     .then(response => {
//       if (!response.ok) {
//         throw new Error(`HTTP error ${response.status}`);
//       }
//       return response.json();
//     });
// }

// function InitializeApp(authConfig) {
//   msalConfig.auth.clientId = authConfig.clientId;
//   msalConfig.auth.authority = authConfig.authority;

//   const msalInstance = new PublicClientApplication(msalConfig);

//   const root = ReactDOM.createRoot(document.getElementById('root'));
//   root.render(
//     <React.StrictMode>
//       <BrowserRouter>
//         <MsalProvider instance={msalInstance}>
//           <App />
//         </MsalProvider>
//       </BrowserRouter>
//     </React.StrictMode>
//   );
// }

// fetchAuthConfig()
//   .then(authConfig => {
//     InitializeApp(authConfig);
//     // const dispatch=useDispatch()
//     // dispatch(setConf(authConfig["conf"]))
//     // console.log(authConfig)

//   })
//   .catch(error => {
//     console.error('Error fetching auth config:', error);
//     // Handle the error, e.g., show an error message to the user
//   });
