import "./App.css";
import React, { useState } from "react";
// import Paperbase from './components/Paperbase';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

// import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import MiniDrawer from "./parent_drawer";
// import BodyDrawer from "./Body_drawer";
// import Chat_bot_body from "./pages/Chatbot_page";
import { Provider } from "react-redux";
import store from "./redux/store";
import LandingPage from "./pages/LandingPage";
// import GPT_Playgound from "./pages/GPT_Playgound";

import Point_in_Time from "./pages/Point_in_Time";
import Admin from "./pages/Admin";
// import Chatbot_page from "./pages/Chatbot_page";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import List_of_menus from "./List_of_menus";
import MainContainer from "./components/Admin/MainContainer";
import Pagel from "../src/Pagel";
import Analytic, { Analytics } from "./components/Analytics";
import Charts from "./Charts";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          // backgroundColor: '#081627',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const MainContent = () => {
  return (
    // <div>
    //   <Routes>
    //     <Route path="/" element={<Pagel />} />
    //     <Route
    //       path="/q_and_a"
    //       element={
    //         <MiniDrawer>
    //           <Point_in_Time />
    //         </MiniDrawer>
    //       }
    //     />
    //     <Route
    //       path="/admin"
    //       element={
    //         <MiniDrawer>
    //           <Admin />
    //         </MiniDrawer>
    //       }
    //     />
    //   </Routes>
    // </div>

    <div>
      <AuthenticatedTemplate>
        <Routes>
          <Route
            path="/"
            element={
              <MiniDrawer>
                <Point_in_Time />
              </MiniDrawer>
            }
          />
          <Route
            path="/admin"
            element={
              <MiniDrawer>
                <Admin />
              </MiniDrawer>
            }
          />
          <Route
            path="/analytics"
            element={
              <MiniDrawer>
                <Charts />
              </MiniDrawer>
            }
          />
        </Routes>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Pagel />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return (
    <Provider store={store}>
      <MainContent />
    </Provider>
  );
}
