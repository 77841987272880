import { React, useEffect, useState } from "react";

import FullWidthTabs from "./Admin_Tabs";

const Admin_Body = () => {
  return (
    <div style={{}}>
      <FullWidthTabs />
    </div>
  );
};

export default Admin_Body;
