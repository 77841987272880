import React, { useState } from "react";
import style from "../Styles/style.module.css";
import { Select } from "@mui/material";
import { Padding } from "@mui/icons-material";

import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import FileUploadComponent from "./Uploader";
const Sidebar = (props) => {
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
  };
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const remainingChars = inputValue.length;
  return (
    <div style={{ width: "100%" }}>
      <hr style={{ marginBottom: "-60px" }} />
      <FileUploadComponent open={props.open} setOpen={props.setOpen} setParam={props.setParam}/>
    </div>
    // <div>
    //   <div className={style.sidelabel}>Name of resource</div>
    //   <div>
    //     <input
    //       style={{
    //         width: 397,
    //         height: 34,
    //         borderRadius: 8,
    //         paddingadding: 14,
    //         marginBottom: 16,
    //         marginLeft: 24,
    //       }}
    //       placeholder="Enter name of the service"
    //       type="text"
    //       value={inputValue}
    //       onChange={handleChange}
    //       maxLength={30}
    //     ></input>
    //     <p style={{ marginLeft: 383, marginTop: -15, color: "gray" }}>
    //       {remainingChars}/30
    //     </p>
    //   </div>
    //   <div className={style.sidelabel}>Serice</div>
    //   <div>
    //     <Select
    //       style={{
    //         width: 397,
    //         height: 34,
    //         borderRadius: 8,
    //         marginBottom: 16,
    //         marginLeft: 24,
    //       }}
    //     ></Select>
    //   </div>
    //   <div className={style.sidelabel}>Cost</div>
    //   <div className="select-container">
    //     <Select
    //       className="select-box"
    //       style={{
    //         width: 397,
    //         height: 34,
    //         borderRadius: 8,
    //         marginBottom: 16,
    //         marginLeft: 24,
    //       }}
    //     ></Select>
    //     <div className={style.container}>
    //       <button
    //         style={{
    //           borderRadius: "0px 8px 8px 0px",
    //           height: 34,

    //           width: 90,
    //         }}
    //       >
    //         USD
    //       </button>
    //     </div>
    //   </div>
    //   <div className={style.sidelabel}>Quality</div>
    //   <div className="container1">
    //     <Select
    //       className="select-box"
    //       style={{
    //         width: 397,
    //         height: 34,
    //         borderRadius: 8,
    //         marginBottom: 16,
    //         marginLeft: 24,
    //       }}
    //     ></Select>
    //     <div className={style.container1}>
    //       <button
    //         style={{
    //           borderRadius: "0px 8px 8px 0px",
    //           height: 34,
    //           width: 90,
    //         }}
    //       >
    //         Watts
    //       </button>
    //     </div>
    //   </div>
    //   <div className={style.sidelabel}>Data quality type</div>
    //   <div>
    //     <Select
    //       style={{
    //         width: 397,
    //         height: 34,
    //         borderRadius: 8,
    //         marginBottom: 16,
    //         marginLeft: 24,
    //       }}
    //     ></Select>
    //   </div>
    //   <div className={style.sidelabel}>Consumption start date</div>
    //   <div>
    //     <input
    //       className={style.data}
    //       type="date"
    //       placeholderText="Select a date"
    //       id="datepicker-id"
    //       name="date-demo"
    //       // onChange={onchangeCallback}
    //       // value={"01/02/2023"}
    //       // style={{ width: "397px", height: 200 }}
    //     ></input>
    //   </div>
    //   <div className={style.sidelabel}>Consumption end date</div>
    //   <input
    //     className={style.data}
    //     placeholderText="Select a date"
    //     type="date"
    //     name="date-demo"
    //     // onChange={onchangeCallback}
    //     // value={"01/02/2023"}
    //   ></input>

    //   <div className={style.sidelabel}>Meter no</div>
    //   <div>
    //     <input
    //       style={{
    //         width: 397,
    //         height: 34,
    //         borderRadius: 8,
    //         marginBottom: 16,
    //         marginLeft: 24,
    //       }}
    //     ></input>
    //     <div
    //       id="date-picker-example"
    //       class="md-form md-outline input-with-post-icon datepicker"
    //       inline="true"
    //     ></div>
    //     <div style={{ marginTop: 200 }}>
    //       <button
    //         onClick={""}
    //         type="button"
    //         id="check"
    //         // onClick={""}
    //         className="btn btn-outline-secondary btn-sm "
    //         style={{ marginLeft: 300 }}
    //       >
    //         Cancel
    //       </button>
    //       <button
    //         className="btn btn-primary btn-sm  overlay "
    //         style={{ marginLeft: 10 }}
    //       >
    //         Create
    //       </button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Sidebar;
