import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Display_Pdf({ open, handleClose, file_url }) {
    console.log(file_url)

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
             
            >
              
                <Button autoFocus color="inherit" onClick={handleClose}>
                    close
                </Button>
                <Box sx={{ background: "grey" }}>
                    <iframe
                      
                        src={file_url}
                    
                        style={{
                            width: "100%",
                            height: '600px'
                        }}


                    ></iframe>

                </Box>
            </Dialog>
        </div>
    );
}