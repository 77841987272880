import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  Divider,
  Chip,
  Select,
  MenuItem,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import CachedIcon from "@mui/icons-material/Cached";
import {
  clearMessages,
  setShowCitations,
  setShowDrawer,
} from "../redux/actions";

import Citations from "../components/Citations";
import Point_in_Time_bot from "../components/Point_in_time_bot";
import "./page.css";
// import "../components/Styles/Chatbot.css";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function Point_in_Time() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.showDrawer);
  const showCitations = useSelector((state) => state.showCitations);
  const knowledgeType = useSelector((state) => state.knowledge_type);
  // const state = ["Al", "Ak", "CL"];
  const product = [
    { label: "Accidental Damage Protection", value: "120" },
    { label: "Travel Insurance Policy", value: "250" },
  ];
  const [states, setStates] = React.useState([]);
  const [selectestate, setSelectstate] = React.useState("");
  const [selectproduct, setSelectproduct] = React.useState("");
  const [selectquery, setSelectquery] = React.useState("");
  const [changeResType, SetchangeResType] = React.useState("chat");
  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleDrawerOpen = () => {
    if (open) dispatch(setShowDrawer(false));
    // setOpen(false);
    else {
      dispatch(setShowDrawer(true));
      dispatch(setShowCitations(false));
    }
  };

  const handleDrawerClose = () => {
    dispatch(setShowDrawer(false));
  };
  const containerStyle = {
    padding: "6px 25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "-45px",
    marginBottom: "10px",
  };

  const divStyle = {
    display: "flex",
    alignItems: "center",
    marginTop: "-10px",
  };

  const imgStyle = {
    position: "absolute",
    top: "-5px",
    right: 0,
    width: "77px",
    height: "120px",
    objectFit: "fill",
  };

  const handleClear = () => {
    setSelectstate("");
    setSelectproduct("");
    setSelectquery("");

    // setSelectquery("");
  };
  const GetStates = () => {
    const BASE_URL = process.env.REACT_APP_API_BASE;
    const API_URL = process.env.REACT_APP_GET_DOC_STATES_API_URL_CHAT;
    const productValue = 1; // Set the product value here

    // Check if a product is selected before making the API call
    if (selectproduct) {
      const URL = `${BASE_URL}${API_URL}&product=${selectproduct}`;

      fetch(URL)
        .then((response) => response.json())
        .then((data) => {
          setStates(data);
          console.log(data);
        })
        .catch((error) => console.log(error));
    }
  };

  React.useEffect(() => {
    // Call GetStates when the component first mounts to avoid an initial API call
    if (selectproduct) {
      GetStates();
    }
  }, [selectproduct]);

  return (
    <div className="">
      <Box sx={{ display: "flex" }}>
        <Main
          open={open}
          sx={{
            background: "white",
            borderRadius: "30px",
            height: "96.5vh",
            paddingTop: "5px",
            px: 0,
            // display: "flex",
            // alignSelf: "stretch",
            // flexDirection: "column",
          }}
        >
          <Box sx={{ borderTopRightRadius: "30px" }}>
            <Toolbar style={{ width: 260 }}>
              <img
                src="./glogo.png"
                style={{ width: 200, marginTop: 18, marginLeft: 6 }}
              />
            </Toolbar>

            <div style={containerStyle}>
              <Typography
                variant="h7"
                noWrap
                sx={{ flexGrow: 1, fontWeight: 600 }}
                component="div"
              ></Typography>
              <div class="drop1">
                <div className="type">
                  <div
                    className={
                      changeResType === "chat"
                        ? "global1 active-chat"
                        : `global1`
                    }
                    onClick={() => {
                      SetchangeResType("chat");
                      console.log(SetchangeResType);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="global-01-icon"
                    >
                      <path
                        d="M7.5 10.5H7.51M12 10.5H12.01M16.5 10.5H16.51M7 18V20.3355C7 20.8684 7 21.1348 7.10923 21.2716C7.20422 21.3906 7.34827 21.4599 7.50054 21.4597C7.67563 21.4595 7.88367 21.2931 8.29976 20.9602L10.6852 19.0518C11.1725 18.662 11.4162 18.4671 11.6875 18.3285C11.9282 18.2055 12.1844 18.1156 12.4492 18.0613C12.7477 18 13.0597 18 13.6837 18H16.2C17.8802 18 18.7202 18 19.362 17.673C19.9265 17.3854 20.3854 16.9265 20.673 16.362C21 15.7202 21 14.8802 21 13.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V14C3 14.93 3 15.395 3.10222 15.7765C3.37962 16.8117 4.18827 17.6204 5.22354 17.8978C5.60504 18 6.07003 18 7 18ZM8 10.5C8 10.7761 7.77614 11 7.5 11C7.22386 11 7 10.7761 7 10.5C7 10.2239 7.22386 10 7.5 10C7.77614 10 8 10.2239 8 10.5ZM12.5 10.5C12.5 10.7761 12.2761 11 12 11C11.7239 11 11.5 10.7761 11.5 10.5C11.5 10.2239 11.7239 10 12 10C12.2761 10 12.5 10.2239 12.5 10.5ZM17 10.5C17 10.7761 16.7761 11 16.5 11C16.2239 11 16 10.7761 16 10.5C16 10.2239 16.2239 10 16.5 10C16.7761 10 17 10.2239 17 10.5Z"
                        stroke="#667085"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Chat
                  </div>

                  <div
                    className={
                      changeResType === "email"
                        ? "enterprise active-email"
                        : `enterprise`
                    }
                    onClick={() => {
                      SetchangeResType("email");
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="global-01-icon"
                    >
                      <path
                        d="M21.5 18L14.8571 12M9.14286 12L2.50003 18M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z"
                        stroke="#667085"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Email
                  </div>
                </div>
                <Select
                  sx={{
                    // border: '1px solid #ccc',
                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    borderRadius: "8px",
                    fontSize: 16,
                    // marginLeft: "10px",
                    // marginTop: "30px",
                    height: "40px",
                    "& .MuiSelect-select": {
                      // paddingTop: "6px",
                      fontSize: "16px",
                    },

                    fontSize: "0.8rem",
                  }}
                  value={selectproduct}
                  onChange={(event) => setSelectproduct(event.target.value)}
                  displayEmpty
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem sx={{ fontSize: "0.8rem" }} value="" disabled>
                    Select Product
                  </MenuItem>
                  {product?.map((option, index) => (
                    <MenuItem
                      sx={{ fontSize: "0.8rem" }}
                      key={index}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>{" "}
                <Select
                  sx={{
                    // border: '1px solid #ccc',
                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    borderRadius: "8px",
                    height: "40px",
                    // fontSize: "16px",
                    marginLeft: "10px",

                    "& .MuiSelect-select": {
                      // paddingTop: "6px",
                      fontSize: 16,
                    },

                    fontSize: "0.8rem",
                  }}
                  value={selectestate}
                  onChange={(event) => setSelectstate(event.target.value)}
                  displayEmpty
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem sx={{ fontSize: "0.8rem" }} value="" disabled>
                    Select State
                  </MenuItem>
                  {states?.map((state) => (
                    <MenuItem
                      sx={{ fontSize: "0.8rem" }}
                      key={state}
                      value={state}
                    >
                      {state}
                    </MenuItem>
                  ))}
                </Select>
                {/* added dropdown */}
              </div>
              <div style={divStyle}>
                {/* <Button
                variant="primary"
                onClick={() => {
                  dispatch(clearMessages());
                  handleClear();
                }}
                sx={{
                  color: "grey",
                  marginLeft: 1,
                  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                  borderRadius: "8px",
                  overflow: "hidden",
                  fontSize: "14px",
                  fontWeight: 500,
                  border: "0.50px #D0D5DD solid",
                  textTransform: "none",
                  bgcolor: "white",
                  marginTop: "-10pxx",
                  height: "40px",
                }}
                // startIcon={<CachedIcon sx={{ color: "#525252" }} />}
              >
                New chat
              </Button> */}
              </div>
            </div>
          </Box>
          <Divider />

          <Point_in_Time_bot
            selectestate={selectestate}
            selectproduct={selectproduct}
            selectquery={selectquery}
            setSelectquery={setSelectquery}
            changeResType={changeResType}
            selectedOption={selectedOption}
            setSelectstate={setSelectstate}
            setSelectproduct={setSelectproduct}
          />
        </Main>

        {/* This drawer is for citations */}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              borderRadius: "30px",
              height: "618px",
              marginTop: "16px",
              marginRight: 1,
            },
            top: "20px",
            zIndex: open ? 0 : -1,
          }}
          style={{ marginTop: "1% !important" }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 1, paddingTop: "15px" }}
          >
            <Typography
              variant="h1"
              sx={{
                color: "#101828",
                fontWeight: 500,
                fontSize: 16,
              }}
            >
              Citations
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Stack>

          <Divider />
          {showCitations ? <Citations /> : <></>}
        </Drawer>
      </Box>
    </div>
  );
}
