import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import './Conf.css';
import { pink } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import { Slider, TextField, Stack, Button, Divider, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import Display_Pdf from './Display_pdf_citation';


// const tempCitations = [
//   {

//     "chunk_text": "Contoso Electronics Employee Handbook\nContoso Electronics This document contains information generated using a language model (Azure OpenAI). The information contained in this document is only for demonstration purposes and does not reflect the opinions or beliefs of Microsoft. Microsoft makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the information contained in this document.\nAll rights reserved to Microsoft Contoso Electronics Employee Handbook Last Updated: 2023-03-05\nContoso Electronics is a leader in the aerospace industry, providing advanced electronic components for both commercial and military aircraft. We specialize in creating cutting- edge systems that are both reliable and efficient. Our mission is to provide the highest quality aircraft components to our customers, while maintaining a commitment to safety and excellence. We are proud to have built a strong reputation in the aerospace industry and strive to continually improve our products and services.",

//     "file_name": "document1.pdf",
//     "file_url": "https://example.com/documents/document1.pdf",
//     "page_url": "https://example.com/documents/document1.pdf?page=1",
//     "score": 0.8,
//     "category": "Animals"
//   },
//   {
//     "chunk_text": "Contoso Electronics Employee Handbook\nContoso Electronics This document contains information generated using a language model (Azure OpenAI). The information contained in this document is only for demonstration purposes and does not reflect the opinions or beliefs of Microsoft. Microsoft makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the information contained in this document.\nAll rights reserved to Microsoft Contoso Electronics Employee Handbook Last Updated: 2023-03-05\nContoso Electronics is a leader in the aerospace industry, providing advanced electronic components for both commercial and military aircraft. We specialize in creating cutting- edge systems that are both reliable and efficient. Our mission is to provide the highest quality aircraft components to our customers, while maintaining a commitment to safety and excellence. We are proud to have built a strong reputation in the aerospace industry and strive to continually improve our products and services.",
//     "file_name": "document2.pdf",
//     "file_url": "https://example.com/documents/document2.pdf",
//     "page_url": "https://example.com/documents/document2.pdf?page=1",
//     "score": 0.6,
//     "category": "Lorem Ipsum"
//   },
//   {
//     "chunk_text": "Contoso Electronics Employee Handbook\nContoso Electronics This document contains information generated using a language model (Azure OpenAI). The information contained in this document is only for demonstration purposes and does not reflect the opinions or beliefs of Microsoft. Microsoft makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the information contained in this document.\nAll rights reserved to Microsoft Contoso Electronics Employee Handbook Last Updated: 2023-03-05\nContoso Electronics is a leader in the aerospace industry, providing advanced electronic components for both commercial and military aircraft. We specialize in creating cutting- edge systems that are both reliable and efficient. Our mission is to provide the highest quality aircraft components to our customers, while maintaining a commitment to safety and excellence. We are proud to have built a strong reputation in the aerospace industry and strive to continually improve our products and services.",
//     "file_name": "document3.pdf",
//     "file_url": "https://example.com/documents/document3.pdf",
//     "page_url": "https://example.com/documents/document3.pdf?page=1",
//     "score": 0.9,
//     "category": "Animals"
//   }
//   // {
//   //   "chunk_text": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. The quick brown fox jumps over the lazy dog. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   //   "file_name": "document4.pdf",
//   //   "file_url": "https://example.com/documents/document4.pdf",
//   //   "page_url": "https://example.com/documents/document4.pdf?page=1",
//   //   "score": 0.7,
//   //   "category": "Lorem Ipsum"
//   // },
//   // {
//   //   "chunk_text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. The quick brown fox jumps over the lazy dog.",
//   //   "file_name": "document5.pdf",
//   //   "file_url": "https://example.com/documents/document5.pdf",
//   //   "page_url": "https://example.com/documents/document5.pdf?page=1",
//   //   "score": 0.5,
//   //   "category": "Lorem Ipsum"
//   // }
// ]



export default function Citations() {

  const dispatch = useDispatch()


  const currentCitations = useSelector((state) => state.citations)

  const [open, setOpen] = React.useState(false);  // model open for pdf display

  const [file_url, setFileUrl]=useState("")

  const handleClickOpen = (url) => {
    setOpen(true);
    setFileUrl(url)
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(currentCitations)


  const Styles = {
    label: {
      marginLeft: '-12px',
      color: '#344054',
      fontWeight: '500',
    },
    select: {
      border: '0.50px #D0D5DD solid',
      marginTop: '12px',
      padding: 0,
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      borderRadius: '8px',
      fontSize: 12,
    },
    radioGroup: {
      marginLeft: '5px',
      flexDirection: 'row', // Display radio buttons horizontally
    },
    radioGreen: {
      color: '#5D923A',
      '&.Mui-checked': {
        color: '#5D923A'
      }

    },
    radioPink: {
      color: '#C11574',
      '&.Mui-checked': {
        color: '#C11574'
      }

    },
    checked: {}, // Empty object to satisfy the makeStyles API
    Chip: {
      cursor: 'pointer', fontWeight: 600, height: '24px', fontSize: '10px', border: 'none'
    },

    slider: {
      marginTop: '-5px',
      '& .MuiSlider-thumb': {

        backgroundColor: '#fff',
        border: '1px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
          boxShadow: 'inherit',
        },
        '&:before': {
          display: 'none',
        },
      }
    }
  };



  return (
    <>

     <Display_Pdf handleClose={handleClose} open={open} file_url={file_url}/>
      <Box sx={{ marginTop: '20px', px: 1 }}>
        <Box style={{
          width: '100%', padding: 16, background: '#F0F9FF', borderRadius: 8, border: '0.50px #7CD4FD solid'

        }}>
          <Typography variant='h1'
            style={{
              alignSelf: 'stretch', color: '#065986', fontSize: 13,
              // fontWeight: '500', 
              paddingBottom: '3px',
              wordWrap: 'break-word'
            }}>
            What is Citation?
          </Typography>
          <Typography
            style={{ alignSelf: 'stretch', color: '#0086C9', fontSize: 12, fontWeight: '400', wordWrap: 'break-word' }}>
            A specific point or piece of information which makeup the answer
          </Typography>
        </Box>

        <Box sx={{ marginTop: '20px' }}>

          {currentCitations.map((item, index) => {
            return (
              <Box sx={{ px: 1,paddingLeft:2 }} 
              
              key={index}
              style={{
                marginBottom: '10px',
                maxHeight: '120px', 
                overflow: 'auto', 
                borderRadius: 8, border: '0.5px solid rgb(255 226 231)'
              }}>

                <Link 
                // href={`${item.file_url}`} 
                onClick={()=>handleClickOpen(`${item.file_url}`)}

                target="_blank" color="inherit" sx={{ fontSize: 12, fontWeight: 600, cursor:"pointer" }}>
                  {item.file_name}  <LaunchOutlinedIcon fontSize='mini' />
                </Link>
                <Typography sx={{ fontSize: 12 }}>
                  {item.chunk_text}
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Box>



    </>
  );
}
