import * as React from "react";
import PropTypes from "prop-types";
// import SwipeableViews from 'react-swipeable-views';
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FileUploadComponent from "./Uploader";
import "./Tabs.css";
import Documents_status_table from "./Docs_status_table";
import { Drawer } from "@mui/material";
import { Select, MenuItem, Button } from "@mui/material";
import { WidthFull } from "@mui/icons-material";
import MainContainer from "./MainContainer";
import MyTable from "./MyTable";

// import FileUploadComponent from "./Uploader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [categories, setCategories] = React.useState([]);

  const [selectedCategorynew, setSelectedCategorynew] = React.useState("");

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);

    console.log(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // const GetCategories = () => {
  //   const BASE_URL = process.env.REACT_APP_API_BASE;
  //   const API_URL = process.env.REACT_APP_POINT_TIME_QNA_API_URL;
  //   const URL = BASE_URL + API_URL;
  //   // console.log(BASE_URL + API_URL);
  //   // alert(URL);
  //   fetch(URL)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setCategories(data["data"]);
  //       console.log(data);
  //     })
  //     .catch((error) => console.log(error));
  // };
  // React.useEffect(() => {
  //   GetCategories();
  // }, []);

  return (
    // <Box
    //   sx={{
    //     bgcolor: "background.paper",
    //     // , maxWidth: 500
    //   }}
    // >
    //   <Box
    //     sx={{
    //       py: 0,
    //       width: "100%",
    //         background: "#ddeff2",
    //       // 'rgb(229, 243, 219)'
    //     }}
    //   >
    //     <Tabs
    //       value={value}
    //       onChange={handleChange}
    //       // indicatorColor="primary"
    //       textColor="inherit"
    //       variant="fullWidth"
    //       aria-label="full width tabs example"
    //     >
    //       {/* <Tab label="Upload Documents" {...a11yProps(0)} /> */}
    //       <Tab label="Documents Status" {...a11yProps(1)} />
    //       {/* <Tab label="" {...a11yProps(2)} /> */}
    //     </Tabs>
    //   </Box>

    //   <TabPanel value={value} index={0} dir={theme.direction}>
    //     <FileUploadComponent handleChange={handleChange} />
    //   </TabPanel>
    //   <TabPanel value={value} index={1} dir={theme.direction}>
    //     <div style={{ padding: "16px" }}>
    //       <Documents_status_table />
    //     </div>
    //   </TabPanel>
    // </Box>
    // <div class="tablebox">
    //   <div style={{ display: "flex" }}>
    //     <button onClick={handleDrawerOpen} class="uploadfile">
    //       Upload file
    //     </button>
    //   </div>
    //   <Documents_status_table />
    //   <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
    //     {/* Drawer content */}
    //     <div style={{ width: "450px" }}>
    //       <FileUploadComponent />
    //     </div>
    //   </Drawer>
    // </div>
    <div>
      <MainContainer />
    </div>
  );
}
