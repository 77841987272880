import { FunctionComponent, useCallback } from "react";
import "./pagel.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
const Pagel = () => {
  const onDropdownHeaderNavigationTri1Click = useCallback(() => {
    // Add your code here
  }, []);

  const onDropdownHeaderNavigationTri3Click = useCallback(() => {
    // Add your code here
  }, []);

  // const handleLogin = () => {
  //   // alert("Clicked");
  //   <Link to="/admin" />;
  // };
  const { instance, accounts } = useMsal();

  const handleLogin = () => {
    // alert("Clicked");
    // <Link to="/admin" />;

    const activeAccount = instance.getActiveAccount();
    // const activeAccount = true;
    if (!activeAccount) {
      instance
        .loginRedirect(loginRequest)
        .then(() => {})
        .catch((e) => {
          // console.log(e);
        });
    }
  };

  return (
    <div className="home-page">
      <div className="main-section">
        <div className="container1">
          <div className="content1">
            <div className="heading-and-supporting-text">
              <div className="heading-and-subheading">
                <div className="subheading">Introducing</div>
                <div className="heading">
                  <p className="azure-openai-pilot">{`AI Pilot for Generali`}</p>
                  <p className="generali"></p>
                </div>
                <div className="subtext">
                  <div className="subheading1">Your AI buddy on</div>
                  <img
                    style={{ width: 225 }}
                    className=""
                    alt=""
                    src="./glogo.png"
                  />
                  {/* <div className="subheading1">OpenAI</div> */}
                </div>
              </div>
            </div>
          </div>
          <img
            className="chatbot-illustration-icon"
            src="./chatbot illustration (1).svg"
          />
        </div>
        {/* <Link to="./q_and_a"> */}
        <button
          class="lunch"
          // variant="contained"
          // sx={{  color: "black" }}
          onClick={handleLogin}
        >
          Launch Chatbot
        </button>
        {/* </Link> */}
      </div>

      {/* <div className="footer">
        <div className="container2">
          <div className="content2">
            <div className="tata-chemicals-svg-parent">
              <div className="tata-chemicals-svg">
                <img className="group-icon" alt="" src="Group.png" />
              </div>
              <div className="footer-text">Copyright © 2023 Generali Group</div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Pagel;
