import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";

import "./parent_drawer.css";
import { useIsAuthenticated } from "@azure/msal-react";

import List_of_menus from "./List_of_menus";

// Import Bootstrap CSS
// import "bootstrap/dist/css/bootstrap.min.css";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
  borderRight: "none",
  background: "#486170",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  borderRight: "none",
  // width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const imgStyle = {
  position: "absolute",
  right: 0,
  width: "60%",
  height: "100%",
  zIndex: -1,
  objectFit: "fill",
};

export default function MiniDrawer(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  // const isAuthenticated = useIsAuthenticated();
  const isAuthenticated = React.useState(true);
  // console.log(isAuthenticated);

  return (
    <>
      {isAuthenticated ? (
        <div className="container-fluid">
          <div className="row">
            <CssBaseline />
            <Drawer variant="permanent" open={open}>
              <div style={{ paddingTop: "20px", height: "100%" }}>
                <List_of_menus username={props.username} />
              </div>
            </Drawer>
            <Box
              component="main"
              className="col"
              sx={{
                paddingRight: 2,
                padding: "12px",
                paddingLeft: "0px",
              }}
            >
              {props.children}
            </Box>
          </div>
        </div>
      ) : (
        <Box>{props.children}</Box>
      )}
    </>
  );
}
