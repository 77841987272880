import React, { useState, useEffect } from "react";
import header from "../Styles/style.module.css";
// import { Typography, Button, TextField, Box } from "@material-ui/core";
// import { PictureAsPdf as PdfIcon } from "@material-ui/icons";
// import { CheckCircle, Cancel, FileCopy as CopyIcon } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
// import { Chip as Chip } from "@material-ui/core";
import { Icon } from "@mui/material";
// import { FileCopyOutlinedIcon } from "@material-ui/icons";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Done as DoneIcon,
  Error as ErrorIcon,
  AccessTime as ProcessingIcon,
} from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Stack from "@mui/material/Stack";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from "@mui/icons-material/History";
import { styled } from "@mui/system";
import { Box, Grid } from "@mui/material";

const renderStatusCell = (params) => {
  let severity = "warning";
  let alertIcon = null;

  if (params.value === " Processing") {
    severity = "info";
    alertIcon = <HistoryIcon fontSize="inherit" style={{ width: 15 }} />;
  } else if (params.value === "Failed") {
    severity = "error";
    alertIcon = <CloseIcon fontSize="inherit" style={{ width: 15 }} />;
  } else if (params.value === "Completed") {
    severity = "success";
    alertIcon = <CheckIcon fontSize="inherit" style={{ width: 15 }} />;
  }

  return (
    <div>
      <Alert
        severity={severity}
        style={{
          width: 150,
          borderRadius: 16,
          height: 28,
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          backgroundColor: params.value === "processing" ? "gray" : "",
        }}
        icon={alertIcon}
      >
        <span
          style={{
            fontSize: `${params.value.length > 20 ? "12px" : "16px"}`,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value}
        </span>
      </Alert>
    </div>
  );
};
// const renderdownloadCell = (params) => {
//   const downloadPDF = async (url) => {
//     try {
//       const response = await fetch(url);
//       const data = await response.blob();
//       const blobURL = URL.createObjectURL(data);
//       const link = document.createElement("a");
//       link.href = blobURL;
//       link.download = "myPDF.pdf"; // Set the desired filename
//       link.click();
//     } catch (error) {
//       console.error("Error downloading PDF:", error);
//     }
//   };
//   return (
//     <div>
//       <a onClick={downloadPDF} href="">
//         Policy
//       </a>
//     </div>
//   );
// };
const MyTable = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [rows, setRows] = useState([]);
  const downloadPDF = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.blob();
      const blobURL = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = "myPDF.pdf"; // Set the desired filename
      link.click();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const columns = [
    // { field: "id", headerName: "Sl.no", width: 70 },
    { field: "Category", headerName: "Category", width: 100 },
    { field: "Product", headerName: "Product", width: 100 },
    { field: "StateLongName", headerName: "State", width: 120 },
    { field: "ProductSubType", headerName: "ProductSubType", width: 150 },
    {
      field: "Status",
      headerName: "Status",
      width: 200,
      renderCell: renderStatusCell,
    },
    { field: "FileName", headerName: "File Name", width: 270 },
    { field: "UploadTime", headerName: "Uploaded Time", width: 180 },
    { field: "UploadedBy", headerName: "Uploaded By", width: 150 },
    // {
    //   headerName: "Download Policy",
    //   width: 100,
    //   renderCell: renderdownloadCell,
    // },
  ];

  const Get_all_rows = () => {
    // Replace with your Flask API URL
    const BASE_URL = process.env.REACT_APP_API_BASE;
    const API_URL = process.env.REACT_APP_GET_DOC_STATUS_TABLE_API_URL;
    const URL = BASE_URL + API_URL;
    // console.log(URL);
    fetch(URL)
      .then((response) => response.json())
      .then((data) => {
        // Process the retrieved data
        // console.log(data); // Print the data to the c
        // You can now manipulate the data as needed
        const row_temp = [];
        if (data.length > 0) {
          let serialNumber = 1;
          data.forEach((row) => {
            row_temp.push({
              id: serialNumber.toString(),
              Category: row.Category,
              Product: row.Product,
              StateLongName: row.StateLongName,
              ProductSubType: row.ProductSubType,
              Status: row.Status,
              FileName: row.FileName,
              UploadTime: row.UploadTime,
              UploadedBy: row.UploadedBy,
            });
            serialNumber++;
          });
        }
        setRows(row_temp);
        // console.log(row_temp);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  React.useEffect(() => {
    Get_all_rows();
  }, []);

  const handlePrevious = () => {
    setActiveTab(activeTab - 1);
  };

  const handleNext = () => {
    setActiveTab(activeTab + 1);
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <Box>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[5]}
            ></DataGrid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default MyTable;
