// import "./styles.css";
import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  AreaChart,
  Area,
  PieChart,
  Pie,
  Scatter,
} from "recharts";

import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  Divider,
  Chip,
  Stack,
  Button,
  IconButton,
} from "@mui/material";

export default function Charts() {
  const [open, setOpen] = useState(false);
  const [graphs, Setgraphs] = useState([]);
  // console.log(graphs, Setgraphs);

  const handleDrawerOpen = () => {
    if (open) setOpen(false);
    else setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const divStyle = {
    position: "relative",
  };
  const drawerWidth = 280;

  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }),
    })
  );

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  }));

  const GetGraphs = () => {
    const BASE_URL = process.env.REACT_APP_API_BASE;
    const API_URL = process.env.REACT_APP_GET_GRAPHS;
    const URL = BASE_URL + API_URL;
    // console.log(URL);
    fetch(URL)
      .then((response) => response.json())
      .then((data) => {
        Setgraphs(data);
        console.log(data);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    GetGraphs();
  }, []);
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Main
        open={open}
        sx={{
          background: "white",
          borderRadius: "30px",
          height: "auto",
          py: 0,
          px: 0,
          paddingTop: "5px",
          // overflow: "auto",
        }}
      >
        <Box sx={{ borderTopRightRadius: "30px" }}>
          <Toolbar>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="174" height="55" viewBox="0 0 174 55" fill="none">
           </svg> */}
            <img
              src="./glogo.png"
              style={{
                width: 200,
                marginTop: 18,
                marginLeft: 6,
                marginBottom: 15,
              }}
            />
          </Toolbar>
        </Box>
        <Divider />
        <div>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              // width={1400}
              height={300}
              data={graphs.statewise_cost}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="State" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="TotalQuestions"
                stroke="#8884d8"
                strokeDasharray="5 5"
              />
              <Line
                type="monotone"
                dataKey="TotalTokens"
                stroke="#82ca9d"
                strokeDasharray="3 4 5 2"
              />
              <Line
                type="monotone"
                dataKey="TotalCost"
                stroke="#82ca9d"
                strokeDasharray="3 4 5 2"
              />
            </LineChart>
          </ResponsiveContainer>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              // width={1400}
              height={300}
              data={graphs.statewise_feedback}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="State" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="FeedbackResponses" stackId="a" fill="#8884d8" />
              <Bar dataKey="CorrectAnswer" stackId="a" fill="#82ca9d" />
              <Bar dataKey="IncorrectAnswer" stackId="a" fill="red" />
            </BarChart>
          </ResponsiveContainer>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              // width={700}
              height={300}
              data={graphs.productwise_feedback}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Product" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="FeedbackResponses" stackId="a" fill="#8884d8" />
              <Bar dataKey="CorrectAnswer" stackId="a" fill="#82ca9d" />
              <Bar dataKey="IncorrectAnswer" stackId="a" fill="red" />
            </BarChart>
          </ResponsiveContainer>

          <div>
            {/* <AreaChart
              width={500}
              height={400}
              data={graphs.statewise_feedback}
              stackOffset="expand"
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="State" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="FeedbackResponses"
                stackId="1"
                stroke="#8884d8"
                fill="#8884d8"
              />
              <Area
                type="monotone"
                dataKey="Thumpsup"
                stackId="1"
                stroke="#82ca9d"
                fill="#82ca9d"
              />
              <Area
                type="monotone"
                dataKey="Thumpsdown"
                stackId="1"
                stroke="#ffc658"
                fill="#ffc658"
              />
              <Area
                type="monotone"
                dataKey="Product"
                stackId="1"
                stroke="#ffc658"
                fill="#ffc658"
              />
            </AreaChart> */}
          </div>
          {/* <div>
            <PieChart width={400} height={400}>
              <Pie
                dataKey="FeedbackResponses"
                isAnimationActive={false}
                data={graphs.productwise_feedback}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              />
              <Pie
                dataKey="Product"
                data={graphs.productwise_feedback}
                cx={500}
                cy={200}
                innerRadius={40}
                outerRadius={80}
                fill="#82ca9d"
              />
              <Pie
                dataKey="Thumpsdown
                "
                data={graphs.productwise_feedback}
                cx={500}
                cy={200}
                innerRadius={40}
                outerRadius={80}
                fill="#82ca9d"
              />
              <Pie
                dataKey="Thumpsup
                "
                data={graphs.productwise_feedback}
                cx={500}
                cy={200}
                innerRadius={40}
                outerRadius={80}
                fill="#82ca9d"
              />
              <Pie
                dataKey="FeedbackResponses"
                data={graphs.productwise_feedback}
                cx={500}
                cy={200}
                innerRadius={40}
                outerRadius={80}
                fill="#82ca9d"
              />
              <Tooltip />
            </PieChart>
          </div> */}
        </div>
      </Main>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            borderRadius: "30px",
            height: "auto",
            marginTop: "16px",
            marginRight: 1,
          },
          top: "20px",
          zIndex: open ? 0 : -1,
        }}
        style={{ marginTop: "1% !important" }}
        variant="persistent"
        anchor="right"
        open={open}
      ></Drawer>
    </Box>
  );
}
