import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { SignOutButton } from "./components/SignOutButton";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsAdmin,
  clearMessages,
  setCitations,
  setShowCitations,
  setShowDrawer,
} from "./redux/actions";
import { useMsal } from "@azure/msal-react";

const List_of_menus = () => {
  const items = [
    // {
    //     screenName: 'Chat',
    //     svgCode1: '<svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none"><path d="M9.5 14L6.42474 17.1137C5.99579 17.548 5.78131 17.7652 5.59695 17.7805C5.43701 17.7938 5.28042 17.7295 5.17596 17.6076C5.05556 17.4672 5.05556 17.162 5.05556 16.5515V14.9916C5.05556 14.444 4.60707 14.0477 4.0652 13.9683V13.9683C2.75374 13.7762 1.72378 12.7463 1.53168 11.4348C1.5 11.2186 1.5 10.9605 1.5 10.4444V5.8C1.5 4.11984 1.5 3.27976 1.82698 2.63803C2.1146 2.07354 2.57354 1.6146 3.13803 1.32698C3.77976 1 4.61984 1 6.3 1H13.7C15.3802 1 16.2202 1 16.862 1.32698C17.4265 1.6146 17.8854 2.07354 18.173 2.63803C18.5 3.27976 18.5 4.11984 18.5 5.8V10M18.5 21L16.3236 19.4869C16.0177 19.2742 15.8647 19.1678 15.6982 19.0924C15.5504 19.0255 15.3951 18.9768 15.2356 18.9474C15.0558 18.9143 14.8695 18.9143 14.4969 18.9143H12.7C11.5799 18.9143 11.0198 18.9143 10.592 18.6963C10.2157 18.5046 9.90973 18.1986 9.71799 17.8223C9.5 17.3944 9.5 16.8344 9.5 15.7143V13.2C9.5 12.0799 9.5 11.5198 9.71799 11.092C9.90973 10.7157 10.2157 10.4097 10.592 10.218C11.0198 10 11.5799 10 12.7 10H18.3C19.4201 10 19.9802 10 20.408 10.218C20.7843 10.4097 21.0903 10.7157 21.282 11.092C21.5 11.5198 21.5 12.0799 21.5 13.2V15.9143C21.5 16.8462 21.5 17.3121 21.3478 17.6797C21.1448 18.1697 20.7554 18.5591 20.2654 18.762C19.8978 18.9143 19.4319 18.9143 18.5 18.9143V21Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    //     svgCode2: '<svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none"><path d="M9.5 14L6.42474 17.1137C5.99579 17.548 5.78131 17.7652 5.59695 17.7805C5.43701 17.7938 5.28042 17.7295 5.17596 17.6076C5.05556 17.4672 5.05556 17.162 5.05556 16.5515V14.9916C5.05556 14.444 4.60707 14.0477 4.0652 13.9683V13.9683C2.75374 13.7762 1.72378 12.7463 1.53168 11.4348C1.5 11.2186 1.5 10.9605 1.5 10.4444V5.8C1.5 4.11984 1.5 3.27976 1.82698 2.63803C2.1146 2.07354 2.57354 1.6146 3.13803 1.32698C3.77976 1 4.61984 1 6.3 1H13.7C15.3802 1 16.2202 1 16.862 1.32698C17.4265 1.6146 17.8854 2.07354 18.173 2.63803C18.5 3.27976 18.5 4.11984 18.5 5.8V10M18.5 21L16.3236 19.4869C16.0177 19.2742 15.8647 19.1678 15.6982 19.0924C15.5504 19.0255 15.3951 18.9768 15.2356 18.9474C15.0558 18.9143 14.8695 18.9143 14.4969 18.9143H12.7C11.5799 18.9143 11.0198 18.9143 10.592 18.6963C10.2157 18.5046 9.90973 18.1986 9.71799 17.8223C9.5 17.3944 9.5 16.8344 9.5 15.7143V13.2C9.5 12.0799 9.5 11.5198 9.71799 11.092C9.90973 10.7157 10.2157 10.4097 10.592 10.218C11.0198 10 11.5799 10 12.7 10H18.3C19.4201 10 19.9802 10 20.408 10.218C20.7843 10.4097 21.0903 10.7157 21.282 11.092C21.5 11.5198 21.5 12.0799 21.5 13.2V15.9143C21.5 16.8462 21.5 17.3121 21.3478 17.6797C21.1448 18.1697 20.7554 18.5591 20.2654 18.762C19.8978 18.9143 19.4319 18.9143 18.5 18.9143V21Z" stroke="#0E1A62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    //     route: '/'

    // },
    {
      screenName: "QnA",
      svgCode1:
        '<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none" verticalAlign="none"><path d="M8.5 6.50224C8.6762 6.00136 9.02397 5.579 9.48173 5.30998C9.93949 5.04095 10.4777 4.9426 11.001 5.03237C11.5243 5.12213 11.999 5.39421 12.3409 5.80041C12.6829 6.20661 12.87 6.72072 12.8692 7.25168C12.8692 8.75056 10.6209 9.5 10.6209 9.5M10.6499 12.5H10.6599M8.4 17.2L9.86 19.1467C10.0771 19.4362 10.1857 19.5809 10.3188 19.6327C10.4353 19.678 10.5647 19.678 10.6812 19.6327C10.8143 19.5809 10.9229 19.4362 11.14 19.1467L12.6 17.2C12.8931 16.8091 13.0397 16.6137 13.2185 16.4645C13.4569 16.2656 13.7383 16.1248 14.0405 16.0535C14.2671 16 14.5114 16 15 16C16.3978 16 17.0967 16 17.6481 15.7716C18.3831 15.4672 18.9672 14.8831 19.2716 14.1481C19.5 13.5967 19.5 12.8978 19.5 11.5V5.8C19.5 4.11984 19.5 3.27976 19.173 2.63803C18.8854 2.07354 18.4265 1.6146 17.862 1.32698C17.2202 1 16.3802 1 14.7 1H6.3C4.61984 1 3.77976 1 3.13803 1.32698C2.57354 1.6146 2.1146 2.07354 1.82698 2.63803C1.5 3.27976 1.5 4.11984 1.5 5.8V11.5C1.5 12.8978 1.5 13.5967 1.72836 14.1481C2.03284 14.8831 2.61687 15.4672 3.35195 15.7716C3.90326 16 4.60218 16 6 16C6.48858 16 6.73287 16 6.95951 16.0535C7.26169 16.1248 7.54312 16.2656 7.7815 16.4645C7.96028 16.6137 8.10685 16.8091 8.4 17.2Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      svgCode2:
        '<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path d="M8.5 6.50224C8.6762 6.00136 9.02397 5.579 9.48173 5.30998C9.93949 5.04095 10.4777 4.9426 11.001 5.03237C11.5243 5.12213 11.999 5.39421 12.3409 5.80041C12.6829 6.20661 12.87 6.72072 12.8692 7.25168C12.8692 8.75056 10.6209 9.5 10.6209 9.5M10.6499 12.5H10.6599M8.4 17.2L9.86 19.1467C10.0771 19.4362 10.1857 19.5809 10.3188 19.6327C10.4353 19.678 10.5647 19.678 10.6812 19.6327C10.8143 19.5809 10.9229 19.4362 11.14 19.1467L12.6 17.2C12.8931 16.8091 13.0397 16.6137 13.2185 16.4645C13.4569 16.2656 13.7383 16.1248 14.0405 16.0535C14.2671 16 14.5114 16 15 16C16.3978 16 17.0967 16 17.6481 15.7716C18.3831 15.4672 18.9672 14.8831 19.2716 14.1481C19.5 13.5967 19.5 12.8978 19.5 11.5V5.8C19.5 4.11984 19.5 3.27976 19.173 2.63803C18.8854 2.07354 18.4265 1.6146 17.862 1.32698C17.2202 1 16.3802 1 14.7 1H6.3C4.61984 1 3.77976 1 3.13803 1.32698C2.57354 1.6146 2.1146 2.07354 1.82698 2.63803C1.5 3.27976 1.5 4.11984 1.5 5.8V11.5C1.5 12.8978 1.5 13.5967 1.72836 14.1481C2.03284 14.8831 2.61687 15.4672 3.35195 15.7716C3.90326 16 4.60218 16 6 16C6.48858 16 6.73287 16 6.95951 16.0535C7.26169 16.1248 7.54312 16.2656 7.7815 16.4645C7.96028 16.6137 8.10685 16.8091 8.4 17.2Z" stroke="#0E1A62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      route: "/",
    },

    {
      screenName: "Admin",
      svgCode1:
        '<svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none"><path d="M7.5 14L11.5 10M11.5 10L15.5 14M11.5 10V19M19.5 14.7428C20.7215 13.734 21.5 12.2079 21.5 10.5C21.5 7.46243 19.0376 5 16 5C15.7815 5 15.5771 4.886 15.4661 4.69774C14.1621 2.48484 11.7544 1 9 1C4.85786 1 1.5 4.35786 1.5 8.5C1.5 10.5661 2.33545 12.4371 3.68695 13.7935" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      svgCode2:
        '<svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none"><path d="M7.5 14L11.5 10M11.5 10L15.5 14M11.5 10V19M19.5 14.7428C20.7215 13.734 21.5 12.2079 21.5 10.5C21.5 7.46243 19.0376 5 16 5C15.7815 5 15.5771 4.886 15.4661 4.69774C14.1621 2.48484 11.7544 1 9 1C4.85786 1 1.5 4.35786 1.5 8.5C1.5 10.5661 2.33545 12.4371 3.68695 13.7935" stroke="#0E1A62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',

      route: "/admin",
    },
    {
      screenName: "Analytics",
      // svgCode1: (
      //   <img
      //     src="/graph-svgrepo-com.svg"
      //     style={{ width: "29px", marginLeft: "9px" }}
      //   />
      // ),
      // svgCode2: (
      //   <img
      //     src="/graph-svgrepo-com.svg"
      //     style={{ width: "29px", marginLeft: "9px" }}
      //   />
      // ),
      svgCode1:
        '<svg fill="white" height="20px" width="23px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 60 60" xml:space="preserve" stroke="white"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M59,54.878h-1v-14H46v14h-3v-23H31v23h-3v-31H16v31h-3v-39H1v39c-0.552,0-1,0.447-1,1s0.448,1,1,1h12h3h12h3h12h3h12h1 c0.552,0,1-0.447,1-1S59.552,54.878,59,54.878z M3,54.878v-37h8v37H3z M18,54.878v-29h8v29H18z M33,54.878v-21h8v21H33z M48,54.878 v-12h8v12H48z"></path> <path d="M11.781,4.989l36.269,20.94l-5.457,0.529c-0.549,0.053-0.952,0.542-0.898,1.092c0.05,0.517,0.485,0.903,0.994,0.903 c0.032,0,0.064-0.002,0.098-0.005l8.563-0.83c0.016-0.001,0.029-0.011,0.044-0.014c0.079-0.011,0.155-0.032,0.23-0.062 c0.014-0.006,0.029-0.003,0.043-0.009c0.01-0.005,0.016-0.014,0.026-0.019c0.003-0.001,0.006-0.002,0.009-0.004 c0.011-0.006,0.024-0.008,0.035-0.014c0.087-0.048,0.166-0.111,0.236-0.185c0.011-0.012,0.021-0.024,0.032-0.037 c0.018-0.02,0.03-0.042,0.046-0.064c0.022-0.03,0.05-0.056,0.069-0.089c0.014-0.024,0.014-0.051,0.026-0.076 c0.016-0.034,0.037-0.065,0.049-0.101c0.013-0.037,0.014-0.075,0.023-0.114c0.012-0.055,0.021-0.107,0.024-0.162 c0.002-0.038,0.007-0.073,0.004-0.111c-0.001-0.012,0.005-0.022,0.003-0.034c-0.003-0.028-0.018-0.05-0.023-0.077 c-0.01-0.056-0.024-0.109-0.044-0.162c-0.009-0.026-0.007-0.052-0.018-0.078l-3.563-7.83c-0.229-0.503-0.822-0.728-1.324-0.496 c-0.503,0.229-0.725,0.821-0.496,1.324l2.27,4.99L12.781,3.256c-0.48-0.276-1.09-0.111-1.366,0.366 C11.139,4.101,11.303,4.713,11.781,4.989z"></path> </g> </g></svg>',
      svgCode2:
        '<svg fill="#000000" height="20px" width="23x" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 60 60" xml:space="preserve"><g><path d="M59,54.878h-1v-14H46v14h-3v-23H31v23h-3v-31H16v31h-3v-39H1v39c-0.552,0-1,0.447-1,1s0.448,1,1,1h12h3h12h3h12h3h12h1c0.552,0,1-0.447,1-1S59.552,54.878,59,54.878z M3,54.878v-37h8v37H3z M18,54.878v-29h8v29H18z M33,54.878v-21h8v21H33z M48,54.878v-12h8v12H48z"/> <path d="M11.781,4.989l36.269,20.94l-5.457,0.529c-0.549,0.053-0.952,0.542-0.898,1.092c0.05,0.517,0.485,0.903,0.994,0.903c0.032,0,0.064-0.002,0.098-0.005l8.563-0.83c0.016-0.001,0.029-0.011,0.044-0.014c0.079-0.011,0.155-0.032,0.23-0.062c0.014-0.006,0.029-0.003,0.043-0.009c0.01-0.005,0.016-0.014,0.026-0.019c0.003-0.001,0.006-0.002,0.009-0.004c0.011-0.006,0.024-0.008,0.035-0.014c0.087-0.048,0.166-0.111,0.236-0.185c0.011-0.012,0.021-0.024,0.032-0.037c0.018-0.02,0.03-0.042,0.046-0.064c0.022-0.03,0.05-0.056,0.069-0.089c0.014-0.024,0.014-0.051,0.026-0.076c0.016-0.034,0.037-0.065,0.049-0.101c0.013-0.037,0.014-0.075,0.023-0.114c0.012-0.055,0.021-0.107,0.024-0.c0.002-0.038,0.007-0.073,0.004-0.111c-0.001-0.012,0.005-0.022,0.003-0.034c-0.003-0.028-0.018-0.05-0.023-0.077c-0.01-0.056-0.024-0.109-0.044-0.162c-0.009-0.026-0.007-0.052-0.018-0.078l-3.563-7.83c-0.229-0.503-0.822-0.728-1.324-0.496c-0.503,0.229-0.725,0.821-0.496,1.324l2.27,4.99L12.781,3.256c-0.48-0.276-1.09-0.111-1.366,0.366C11.139,4.101,11.303,4.713,11.781,4.989z"/></g></svg>',

      route: "/analytics",
    },

    // Add more items here
  ];

  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();

  const is_admin = useSelector((state) => state.isAdmin);
  // console.log(accounts);

  function getMatchingRoute(routes) {
    const currentPath = window.location.pathname;
    const matchingRoute = routes.find((route) => route.route === currentPath);
    // console.log(matchingRoute);
    const matchingRouteIndex = routes.findIndex(
      (route) => route.route === currentPath
    );
    setSelectedItem(matchingRouteIndex);
  }

  useEffect(() => {
    getMatchingRoute(items);
    const is_admin = true;
    // console.log(is_admin);
    dispatch(setIsAdmin(true));
    // console.log("helloout", accounts[0]);

    if (accounts[0]["idTokenClaims"]["roles"]) {
      console.log("hello", accounts[0]);
      console.log(accounts[0]["idTokenClaims"]["roles"]);
      const is_admin = accounts[0]["idTokenClaims"]["roles"];

      if (is_admin.indexOf("admin") !== -1) {
        console.log(`${accounts[0]["name"]} is Admin`);
        dispatch(setIsAdmin(true));
      }
    }
  }, [accounts]);
  const navigate = useNavigate();

  const handleItemClick = (index) => {
    setSelectedItem(index);

    // console.log(index)

    const route = items[index]["route"];
    dispatch(clearMessages());
    dispatch(setCitations([]));
    dispatch(setShowCitations(false));
    dispatch(setShowDrawer(false));
    navigate(route);
  };

  // console.log(username);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "90%", // Or any desired height
        position: "relative",
        paddingTop: "85px",
      }}
    >
      {/* <List>


                {items.map((item, index) => (
                    <ListItem
                        key={index}
                        style={{ background: 'pink' }}

                    >

                        <ListItemButton onClick={() => handleItemClick(index)}
                            style={{
                                background: selectedItem === index ? 'white' : 'transparent',
                            }}
                            sx={{ background: selectedItem === 'Chat' ? 'white' : '', borderRadius: 1, textAlign: "center" }} >


                            <div style={{ background: 'yellow', fontSize: '0.8rem' }}>
                                {item.screenName}
                                <div style={{ top: 3 }}> </div>
                            <div dangerouslySetInnerHTML={{ __html: selectedItem === index ? item.svgCode2 : item.svgCode1 }} />
                            <div style={{ marginLeft: '-2px', width: "100%", alignSelf: 'stretch', height: 13, textAlign: 'center', color: 'black', fontFamily: '', fontWeight: '400', wordWrap: 'break-word' }}>{item.screenName}</div>
                        
                            </div>
                        </ListItemButton>


                    </ListItem>
                ))}
            </List> */}

      {/* {items.map((item, index) => (
                
                <div key={index}   >
                    <div style={{
                        cursor: "pointer",
                        borderRadius: "6px",
                        background: selectedItem === index ? 'white' : 'transparent',
                        margin: '5px 15px',
                        textAlign: 'center',
                        fontSize: '0.75rem',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        paddingTop: '10px',
                        paddingBottom: '5px',
                    }}
                        onClick={() => handleItemClick(index)}
                    >
                        <div dangerouslySetInnerHTML={{ __html: selectedItem === index ? item.svgCode2 : item.svgCode1 }} />

                        <p style={{ lineHeight: 0, color: selectedItem === index ? '#0E1A62' : 'white' }}>{item.screenName}</p>
                    </div>
                </div>
            ))} */}
      {items.map((item, index) => (
        <div key={index}>
          {item.screenName === "Admin" ? (
            is_admin ? (
              <div
                style={{
                  cursor: "pointer",
                  borderRadius: "6px",
                  background: selectedItem === index ? "white" : "transparent",
                  margin: "5px 15px",
                  textAlign: "center",
                  fontSize: "0.75rem",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  paddingTop: "10px",
                  paddingBottom: "0px",
                  height: 68,
                  width: 59,
                }}
                onClick={() => handleItemClick(index)}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      selectedItem === index ? item.svgCode2 : item.svgCode1,
                  }}
                />
                <p
                  style={{
                    // lineHeight: 0,
                    paddingBottom: "8px",
                    color: selectedItem === index ? "#0E1A62" : "white",
                    marginTop: 4,
                  }}
                >
                  Upload
                </p>
              </div>
            ) : null
          ) : (
            <div
              style={{
                cursor: "pointer",
                borderRadius: "6px",
                background: selectedItem === index ? "white" : "transparent",
                margin: "5px 15px",
                textAlign: "center",
                fontSize: "0.75rem",
                paddingLeft: "5px",
                paddingRight: "5px",
                paddingTop: "10px",
                // paddingBottom: "5px",
                height: 68,
                width: 59,
              }}
              onClick={() => handleItemClick(index)}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    selectedItem === index ? item.svgCode2 : item.svgCode1,
                }}
              />
              <p
                style={{
                  // lineHeight: 0,
                  paddingBottom: "8px",
                  color: selectedItem === index ? "#0E1A62" : "white",
                  marginTop: 4,
                }}
              >
                {item.screenName}
              </p>
            </div>
          )}
        </div>
      ))}

      <div
        style={{
          position: "absolute",
          bottom: 0,
          cursor: "pointer",
          borderRadius: "6px",
          margin: "15px 15px 0px 15px",
          textAlign: "center",
          fontSize: "0.75rem",
          paddingLeft: "5px",
          paddingRight: "5px",
          paddingTop: "10px",
          paddingBottom: "0px",
        }}
      >
        <SignOutButton />
        {/* {console.log(username)} */}
      </div>
    </div>
  );
};

export default List_of_menus;
