import { useDispatch, useSelector } from "react-redux";
import {
  addUserMessage,
  addBotResponse,
  clearMessages,
  setInput_message,
  setfull_conversation,
  setLoading,
  setCitations,
  setShowDrawer,
  setShowCitations,
  setHistory,
} from "../redux/actions";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  SpeechRecognizer,
  SpeechSynthesizer,
  SpeechSynthesisResult,
  SpeechConfig,
  SpeechRecognitionEventArgs,
  AudioConfig,
  ResultReason,
  PhraseListGrammar,
} from "microsoft-cognitiveservices-speech-sdk";
import {
  Paper,
  Box,
  Stack,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Avatar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Send as SendIcon,
  Clear as ResetIcon,
  ThumbDownAltSharp,
} from "@mui/icons-material";
import "./Styles/Chatbot2.css";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import Autocomplete from "@mui/material/Autocomplete";
import ThumbUpOffAltTwoToneIcon from "@mui/icons-material/ThumbUpOffAltTwoTone";

import SpeechToText from "./AzureSpeech2";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import Picker from "emoji-picker-react";
// import azureTextToSpeech from "./Text_to_speech";
// import * as speechsdk from "microsoft-cognitiveservices-speech-sdk"; // Import the speech SDK

import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";

import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpAltSharpIcon from "@mui/icons-material/ThumbUpAltSharp";
// import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
// import { SpeechSynthesisOutputFormat } from "microsoft-cognitiveservices-speech-sdk";
// import SpeechToText from "@mui/icons-material";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import MicOffIcon from "@mui/icons-material/MicOff";
// import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const style = {
  root: {
    border: 0,
  },
};

const stylemodal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  height: 230,
};

var recognizer;
var speechsdk = require("microsoft-cognitiveservices-speech-sdk");

const Point_in_Time_bot = (props) => {
  const knowledgeType = useSelector((state) => state.knowledge_type);
  const [val, setVal] = useState("");
  const [mic, setmic] = useState(false);
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages);
  const input_message = useSelector((state) => state.input_message);
  const full_conversataion = useSelector(
    (state) => state.full_conversataion_from_backend
  );
  const temperature = useSelector((state) => state.temperature);
  const past_messages_included = useSelector(
    (state) => state.past_messages_included
  );
  const system_message = useSelector((state) => state.system_message);
  const chatContainerRef = useRef(null);
  const showLoader = useSelector((state) => state.loading);
  const [content, setContent] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [query, setQuery] = React.useState([]);
  const [textToCopy, setTextToCopy] = useState();
  const [copySuccess, setCopySuccess] = useState(false);
  const [citations2, setCitations] = useState([]);
  const [isClickedpast, setIsClickedpast] = useState(false);
  const [feedbacklatest, setfeedbacklatest] = useState();
  const [thumpsdown, setthumpsdown] = useState();
  const htmlRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [feedback, setfeedback] = useState(0);
  const [displayText, setDisplayText] = useState(
    "INITIALIZED: ready to test speech..."
  );
  const [player, updatePlayer] = useState({ p: undefined, muted: false });
  const [voice, setVoice] = useState(false); // State to control speech recognition
  const [isRecording, setIsRecording] = useState(false);
  const [recognizedText, setRecognizedText] = useState("");
  const [languageButton, setLanguageButton] = useState("en-US");

  const handleRadioChange = (event) => {
    setLanguageButton(event.target.value);
    console.log("Selected language:", event.target.value);
    dispatch(setInput_message(""));
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (feedback) => {
    if (val === "") {
    } else {
      setOpen(false);

      setVal("");
    }
    // setIsClickedup({});
    // setIsClickeddoen({});
  };

  useEffect(() => {
    // Scroll to the bottom of the chat container whenever messages update
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [messages]);

  const handleInputChange = (event) => {
    dispatch(setInput_message(event.target.value));
    // setQuery();
    props.setSelectquery("");
  };

  const onEmojiClick = (event, emojiObject) => {
    // console.log(emojiObject);
    setChosenEmoji(emojiObject);
    const message_str = input_message + emojiObject.emoji;
    dispatch(setInput_message(message_str));
    setShowEmojiPicker(!showEmojiPicker);
  };

  const startRecording = () => {
    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    const speechConfig = speechsdk.SpeechConfig.fromSubscription(
      "9f7245b308e645c4832bb16f71a5767d",
      "westus2"
    );
    speechConfig.speechRecognitionLanguage = languageButton;
    recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
    setIsRecording(true);
    setRecognizedText("");
    console.log(recognizer);
    recognizer.recognized = (s, e) => {
      if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
        console.log(e.result.text);
        setRecognizedText((prevText) => prevText + e.result.text + " ");
      }
    };
    recognizer.canceled = (s, e) => {
      console.log(e);
      recognizer.stopContinuousRecognitionAsync();
    };
    recognizer.sessionStopped = (s, e) => {
      console.log(e);
      recognizer.stopContinuousRecognitionAsync();
    };
    recognizer.startContinuousRecognitionAsync();
  };
  useEffect(() => {
    dispatch(setInput_message(recognizedText));
  }, [recognizedText]);

  const stopmic = () => {
    try {
      setIsRecording(false);
      console.log(recognizer, "StopMethod");
      // Stop recognition
      recognizer.close();
    } catch (error) {
      console.error("An error occurred while stopping: " + error);
    }
  };

  // const top100Films = [
  //   { title: "abcense" },
  //   { title: "activities" },
  //   { title: "address" },
  //   { title: "cancellation" },
  //   { title: "car" },
  //   { title: "claim" },
  //   { title: "concierge" },
  //   { title: "customer" },
  //   { title: "deadline " },
  //   { title: "deal" },
  //   { title: "death" },
  //   { title: "delay" },
  //   { title: "delayed" },
  //   { title: "disable" },
  //   { title: "documentation " },
  //   { title: "domestic" },
  //   { title: "embassy" },
  //   { title: "emergencies" },
  //   { title: "exclusions" },
  //   { title: "expenses" },
  //   { title: "family" },
  //   { title: "fire" },
  //   { title: "hospitalization" },
  //   { title: "hotel" },
  //   { title: "hurricane" },
  //   { title: "hurricane" },
  //   { title: "illness" },
  //   { title: "international" },
  //   { title: "interruption" },
  //   { title: "job" },
  //   { title: "limit " },
  //   { title: "limitations" },
  //   { title: "live-in" },
  //   { title: "medical" },
  //   { title: "member" },
  //   { title: "pandemic" },
  //   { title: "policy" },
  //   { title: "precense" },
  //   { title: "pre-existing" },
  //   { title: "process" },
  //   { title: "reimbursement" },
  //   { title: "related" },
  //   { title: "rental" },
  //   { title: "required" },
  //   { title: "service" },
  //   { title: "sickness" },
  //   { title: "specific " },
  //   { title: "spouse" },
  //   { title: "state" },
  //   { title: "stranded" },
  //   { title: "submitting " },
  //   { title: "time " },
  //   { title: "toll-free" },
  //   { title: "trip" },
  //   { title: "typically" },
  //   { title: "unable" },
  //   { title: "vaccination" },
  //   { title: "vrbo" },
  //   { title: "work" },
  // ];

  const latest = async (q) => {
    alert(q);
  };
  const handledown = (index, feedback) => {
    console.log(feedback);
    if (isClickeddown[index]) {
      let a = [...isClickeddown];
      a[index] = false;
      console.log(a);
      setIsClickeddoen(a);
      Feedbackdown(feedback);
    } else {
      handleOpen();
      setfeedback(feedback);
      let a = [...isClickeddown];
      a[index] = true;
      console.log(a);
      setIsClickeddoen(a);
      let b = [...isClickedup];
      b[index] = false;
      setIsClickedup(b);
    }
  };
  const handleup = (index, feedback) => {
    console.log(feedback);
    if (isClickedup[index]) {
      let a = [...isClickedup];
      a[index] = false;
      console.log(a);
      setIsClickedup(a);
      Feedbackup(feedback);
    } else {
      let a = [...isClickedup];
      a[index] = true;
      console.log(a);
      setIsClickedup(a);
      let b = [...isClickeddown];
      b[index] = false;
      setIsClickeddoen(b);

      Feedbackup(feedback);
    }
  };
  const characterCount = val.length;
  const characterLimit = 800;

  // const feed = citations2.query_id;
  const Feedbackup = async (feedback) => {
    try {
      const body = {
        question_id: feedback,
        feedback_score: 1,
        feedback_by: sessionStorage.username,
        feedback_comment: "None",
      };
      const BASE_URL = process.env.REACT_APP_API_BASE;
      const API_URL = process.env.REACT_APP_FEEDBACK_API_URL;
      const URL = BASE_URL + API_URL;
      // console.log(URL);
      const response = await fetch(URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      console.log(body);

      toast.success("feedback send successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
      // setfeedbacklatest();
      // setIsClickedup(!isClickedup);
      // setIsClickeddoen(false);
    } catch (error) {
      toast.error("Error While sending feedback:", error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    }
  };

  const Feedbackdown = async () => {
    try {
      const body = {
        feedback_score: 0,
        feedback_by: sessionStorage.username,
        feedback_comment: val.replace(new RegExp(/[^a-zA-Z0-9 ]/g), ""),
        question_id: feedback,
      };

      const BASE_URL = process.env.REACT_APP_API_BASE;
      const API_URL = process.env.REACT_APP_FEEDBACK_API_URL;
      const URL = BASE_URL + API_URL;
      // console.log(URL);
      const response = await fetch(URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      console.log(body);
      if (val === "") {
        toast.error("Please give feddback", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 500,
        });
      } else {
        toast.success("feedback send successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 500,
        });
      }
      // setfeedbacklatest();
      // setIsClickedup(!isClickedup);
      // setIsClickeddoen(false);
    } catch (error) {
      toast.success("Error While sending feedback:", error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    }
  };

  const EnterpriseKnowledge = async (body, voice_output) => {
    try {
      const body = {
        question: input_message.replace(new RegExp(/[^a-zA-Z0-9 ]/g), ""),
        chathistory: "", //Pass this as blank as of now
        state: props.selectestate,
        product: props.selectproduct,
        is_hybrid_search: "0", //Always pass this as 1
        chat_type: props.changeResType,
        is_with_langchain: "0",
        user: sessionStorage.username,
      };
      console.log("resquest body :", body);
      // console.log(props.changeResType);

      const BASE_URL = process.env.REACT_APP_API_BASE;
      const API_URL = process.env.REACT_APP_POINT_TIME_QNA_API_URL;
      const URL = BASE_URL + API_URL;
      // console.log(URL);
      const response = await fetch(URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      console.log(data);
      setCitations(data);
      // console.log(setCitations);
      console.log(citations2);
      // console.log(data);
      const botResponse = data.answer;
      const query_id = data.query_id;
      // console.log(data.answer);
      // if (data.content) setContent(data.content);
      var count = Object.keys(data.citation).length;
      let citationsPresent = count > 0 ? true : false;
      if (citationsPresent == true) citationsPresent = true;
      else citationsPresent = false;
      const citations = data.content;
      dispatch(
        addBotResponse(
          botResponse,
          citationsPresent,
          citationsPresent ? data.citation : [],
          query_id
        )
      );
      // const botMessage = {
      //   response:
      //     "We are experiencing some network issues at the moment.Thanks for your patience, please relax while our experts are on it.",
      //   user: false,
      // };
      // dispatch(addBotResponse(botMessage.response));
      if (voice_output) {
        // const synth = azureTextToSpeech(botResponse);
      }

      return botResponse;
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data:", error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
      const botMessage = {
        response:
          "We are experiencing some network issues at the moment.Thanks for your patience, please relax while our experts are on it.",
        user: false,
      };
      dispatch(addBotResponse(botMessage.response));
    } finally {
      dispatch(setLoading(false));
    }
  };
  // console.log(citations2);

  // console.log(props.selectstate, props.selectproduct);
  const handleInputSubmit = (input_message, voice_output = false) => {
    // console.log("clicked");
    console.log(input_message);
    if (props.selectestate == "" && props.selectproduct == "") {
      toast.error("Select State and Product", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    } else if (props.selectestate !== "" && props.selectproduct == "") {
      toast.error("Select Product", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    } else if (props.selectestate == "" && props.selectproduct !== "") {
      toast.error("Select State", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
      });
    } else {
      if (input_message.trim() === "") {
        return;
      }
      dispatch(setLoading(true));
      dispatch(addUserMessage(input_message));

      let body = {
        // question: input_message,
        // conversation: full_conversataion,
        // temperature: temperature,
        // system_message: system_message,
        // past_messages: past_messages_included,
        question: input_message.replace(new RegExp(/[^a-zA-Z0-9 ]/g), ""),
        chathistory: "", //Pass this as blank as of now
        state: props.selectestate,
        product: props.selectproduct,
        is_hybrid_search: "0", //Always pass this as 1
        chat_type: props.changeResType,
        is_with_langchain: "0",
        user: sessionStorage.username,
      };
      console.log("resquest body :", body);
      console.log(props.username);

      dispatch(setInput_message(""));
      let response = "";
      EnterpriseKnowledge();
      props.setSelectquery("");

      // if (voice_output) azureTextToSpeech(response);
    }
  };

  // const [changeResType, SetchangeResType] = useState("chat");

  const Getquery = () => {
    const BASE_URL = process.env.REACT_APP_API_BASE;
    const API_URL = process.env.REACT_APP_GET_DOC_QUERY_API_URL;
    // const URL = BASE_URL + API_URL;
    if (props.selectproduct) {
      const URL = `${BASE_URL}${API_URL}?product=${props.selectproduct}`;

      fetch(URL)
        .then((response) => response.json())
        .then((data) => {
          setQuery(data);
          console.log(data);
        })
        .catch((error) => console.log(error));
    }
  };

  React.useEffect(() => {
    // Call GetStates when the component first mounts to avoid an initial API call
    if (props.selectproduct) {
      Getquery();
    }
  }, [props.selectproduct]);

  const handleClear = () => {
    dispatch(clearMessages());
    dispatch(setInput_message(""));
    props.setSelectstate("");
    props.setSelectproduct("");
    props.setSelectquery("");
    setIsClickedup({});
    setIsClickeddoen({});
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleInputSubmit(input_message);
    }
  };

  const handleCitations = (citations) => {
    console.log(citations);
    dispatch(setShowCitations(true));
    dispatch(setShowDrawer(true));
    dispatch(setCitations(citations));
  };

  const UserMessage = ({ text }) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            marginTop: "2%",
            marginBottom: "2%",
          }}
        >
          <div
            style={{
              padding: 14,
              borderRadius: 8,
              backgroundColor: "#F2F4F7",
              maxWidth: "55%",
              wordBreak: "break-word",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>

          <img
            style={{
              width: 30,
              marginLeft: 10,
              marginRight: 10,
              alignSelf: "flex-start",
            }}
            src="./user.png"
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    var hist = sessionStorage.getItem("items");
    if (hist && hist.length > 0) {
      dispatch(setHistory(JSON.parse(hist)));
    }
  }, []);
  useEffect(() => {
    sessionStorage.setItem("items", JSON.stringify(messages));
    // sessionStorage.setItem("query_id", citation2.query_id);
  }, [messages]);

  const [isClickedup, setIsClickedup] = useState([]);
  const [isClickeddown, setIsClickeddoen] = useState([]);
  // console.log(isClickedup);

  const BotMeesage = ({
    message,
    knowledgeType,
    changeResType,
    index,
    citations2,
  }) => {
    const text = message.text;
    const feedback = message.query_id;
    const citations_present = message.citations_present;
    const citations = message.citations;
    const [showtext, setShowtext] = useState(false);
    const handleClear = () => {
      setShowtext(!showtext);
    };

    return (
      <div
        style={{
          maxWidth: "60%",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 12,
          // flexDirection: "row-reverse",
          display: "inline-flex",
        }}
      >
        <img src="./chat.png" style={{ width: 30 }} />
        <div
          style={{
            alignSelf: "stretch",
            background: "#6F7072",
            borderRadius: "8px",
            overflow: "hidden",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            // gap: 16,
            display: "flex",
          }}
        >
          <div>
            {index !== 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 4,
                  marginRight: 15,
                  // justifyContent: "space-around",
                }}
              >
                <IconButton
                  onClick={(event) => {
                    // Feedbackup(feedback);
                    // // setIsClickedup(!isClickedup);
                    // // setIsClickeddoen(false);
                    // setIsClickedup((prev) => ({
                    //   ...prev,
                    //   [`${index}`]: true,
                    // }));
                    // setIsClickeddoen((prev) => ({
                    //   ...prev,
                    //   [`${index}`]: false,
                    // }));
                    handleup(index, feedback);
                  }}
                >
                  {isClickedup[`${index}`] ? (
                    <ThumbUpAltSharpIcon
                      style={{ width: 20, color: "white" }}
                    />
                  ) : (
                    <ThumbUpOffAltIcon style={{ width: 25, color: "white" }} />
                  )}
                </IconButton>
                <IconButton
                  onClick={(event) => {
                    // handleOpen();
                    // setthumpsdown(feedback);
                    // // Feedbackdown(feedback);
                    // // setIsClickeddoen(!isClickeddown);
                    // // setIsClickedup(false);
                    // setIsClickeddoen((prev) => ({
                    //   ...prev,
                    //   [`${index}`]: true,
                    // }));
                    // setIsClickedup((prev) => ({
                    //   ...prev,
                    //   [`${index}`]: false,
                    // }));
                    handledown(index, feedback);
                  }}
                  style={{ marginLeft: "-5px" }}
                >
                  {/* {console.log(isClickeddown)} */}
                  {isClickeddown[`${index}`] ? (
                    <ThumbDownAltSharp
                      style={{ width: 20, color: "white" }}
                      // onClick={handledown}
                    />
                  ) : (
                    <ThumbDownOffAltIcon
                      style={{ width: 25, color: "white" }}
                      // onClick={handledown}
                    />
                  )}
                </IconButton>
                <IconButton>
                  <ContentPasteIcon
                    onClick={() => handleCopyClick(text)}
                    style={{
                      width: 20,
                      color: "white",
                      cursor: "pointer",
                      marginLeft: "-5px",

                      // color: isClickedpast ? "blue" : "white",
                    }}
                  />
                </IconButton>
              </div>
            )}
            {/* {console.log(props.changeResType)} */}

            <div
              style={{
                color: "white",
                padding: 16,
                whiteSpace: changeResType === "email" ? "pre-wrap" : "pre-wrap",
              }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
            {citations_present ? (
              <div style={{ width: "100%" }}>
                <hr style={{ color: "white", marginTop: -8 }} />
                <div
                  style={{
                    // display: "flex",
                    paddingLeft: 11,
                    paddingRight: 11,
                    paddingBottom: 6,
                    marginTop: "-6px",
                  }}
                >
                  <label style={{ color: "white" }}>Reference :</label>
                  <div style={{ marginLeft: 85, marginTop: -21 }}>
                    <div
                      style={{
                        border: "none",
                        marginLeft: "7px",
                        background: "white",
                        color: "white",
                        borderRadius: "3px",
                        fontSize: "14px",
                        // textDecoration: "underline",
                        paddingLeft: 6,
                        paddingRight: 6,
                        paddingTop: 2,
                        marginBottom: 6,
                        width: "fit-content",
                      }}
                    >
                      <div style={{ color: "#0a58ca" }}>
                        Title:{citations2?.title}
                      </div>
                    </div>

                    <div
                      style={{
                        border: "none",
                        marginLeft: "7px",
                        background: "white",
                        color: "white",
                        borderRadius: "3px",
                        fontSize: "14px",
                        // textDecoration: "underline",
                        paddingLeft: 6,
                        paddingRight: 6,
                        paddingTop: 2,
                        marginBottom: 6,
                        width: "fit-content",
                      }}
                    >
                      <div style={{ color: "#0a58ca" }}>
                        Page No :{citations2?.page_no}
                      </div>
                    </div>
                    <div
                      style={{
                        border: "none",
                        marginLeft: "7px",
                        background: "white",
                        color: "white",
                        borderRadius: "3px",
                        fontSize: "14px",
                        // textDecoration: "underline",
                        paddingLeft: 6,
                        paddingRight: 6,
                        paddingTop: 2,
                        marginBottom: 6,
                        width: "fit-content",
                      }}
                    >
                      <a
                        href={citations2?.citation_url}
                        style={{
                          borderRadius: 8,
                          textDecoration: "none",
                          color: "rgb(10, 88, 202)",
                        }}
                        // key={index}
                        target="_blank"
                        // onClick={() => handlebutton(citations.url)}
                      >
                        {/* {console.log(citations2)} */}
                        URL:{citations2?.citation_filename}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* {index !== 0 && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: "#6F7072" }}
                >
                  <Typography sx={{ color: "white" }}>Citation</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: "#6F7072" }}>
                  <Typography>citation</Typography>
                </AccordionDetails>
              </Accordion>
            )} */}
          </div>
        </div>
      </div>
    );
  };

  const WarningAbout_Conv_history = () => {
    return (
      <div
        style={{
          marginBottom: "",
          width: "100%",
          paddingLeft: 32,
          paddingRight: 32,
          paddingTop: 5,
          paddingBottom: 5,
          background: "#FFFAEB",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 4,
          display: "inline-flex",
        }}
      >
        {/* <div
          style={{
            flex: "1 1 0",
            height: 28,
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 16,
            display: "flex",
          }}
        >
          <div
            style={{
              width: 28,
              height: 28,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <IconButton size="small" sx={{ bgcolor: "#FEF0C7" }}>
              <ReportProblemOutlinedIcon
                sx={{ color: "#DC6803", fontSize: 16 }}
              />
            </IconButton>
          </div>

          <Typography
            sx={{
              color: "#B54708",
              fontSize: 12,
              fontFamily: "",
              fontWeight: "500",
              wordWrap: "break-word",
            }}
          >
            This will not retain any context of the previous questions
          </Typography>
        </div> */}
      </div>
    );
  };

  const handleCopyClick = (text) => {
    // const range = document.createRange();
    // range.selectNode(htmlRef.current);
    // const selection = window.getSelection("textarea");
    // selection.removeAllRanges();
    // selection.addRange(range);
    // document.execCommand("copy");
    // selection.removeAllRanges();
    // // console.log(selection);
    // toast.success("Copied !", {
    //   position: toast.POSITION.TOP_CENTER,
    //   autoClose: 500,
    // });
    // Create a temporary input element to copy the text
    const tempInput = document.createElement("textarea");
    // Remove the HTML tags from the text using a regular expression
    var regexp = /<[^>]*>/g;
    var result = text.replaceAll(regexp, "");
    tempInput.value = result;
    // tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    // console.log("in remove part" + result);
    // Execute the copy command
    document.execCommand("copy");
    // Remove the temporary input
    document.body.removeChild(tempInput);
    // console.log(text, tempInput.value);
    //   Set copySuccess to true setCopySuccess(true);
    toast.success("Copied !", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 500,
    });
    // // Create a temporary div element
    // const tempDiv = document.createElement("div");
    // tempDiv.value = text;
    // // Append the temporary div to the document body
    // document.body.appendChild(tempDiv);
    // // Select the text within the div
    // const range = document.createRange();
    // range.selectNode(tempDiv);
    // window.getSelection().removeAllRanges();
    // window.getSelection().addRange(range);
    // try {
    //   // Copy the selected text to the clipboard
    //   document.execCommand("copy");
    //   // Set the copy success to true
    //   setCopySuccess(true);
    //   // Show a success toast
    //   toast.success("Copied to clipboard", {
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 5000, // 5000 milliseconds (5 seconds) or any desired value
    //   });
    // } catch (err) {
    //   // Handle any errors that might occur during copying
    //   console.error("Copy failed: ", err);
    //   setCopySuccess(false);
    // }
    // // Remove the temporary div element
    // document.body.removeChild(tempDiv);
  };

  return (
    <>
      {/* <WarningAbout_Conv_history /> */}
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <Box
            component="main"
            className="main_content"
            sx={{
              flex: 1,
              py: 2,
              px: 3,
              // bgcolor: '#fbfbfb'
            }}
          >
            <div
              ref={chatContainerRef}
              style={{
                height: "50vh",
                overflowY: "auto",

                // flexDirection: "row-reverse",
              }}
            >
              {/* {console.log(messages)} */}
              {messages.length &&
                messages.map((message, index) => (
                  <div key={index}>
                    {message.fromUser ? (
                      <>
                        <UserMessage
                          text={message.text}
                          knowledgeType={knowledgeType}
                        />
                      </>
                    ) : (
                      <>
                        <div ref={htmlRef}>
                          <BotMeesage
                            index={index}
                            message={message}
                            knowledgeType={knowledgeType}
                            citations2={message.citations}
                            changeResType={props.changeResType}
                          />
                        </div>
                      </>
                    )}
                  </div>
                ))}

              {showLoader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "10px",
                  }}
                >
                  {/* <Avatar alt="Bot" src={bot} variant="square" /> */}

                  <div
                    style={{
                      width: 56,
                      height: 56,
                      position: "relative",
                      // background: "#E9F5FB",
                      borderRadius: 200,
                    }}
                  >
                    <div
                      style={{
                        width: 32,
                        height: 32,
                        left: 12,
                        top: 12,
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          width: 28.53,
                          height: 25.15,
                          left: 1.74,
                          top: 3.43,
                          position: "absolute",
                        }}
                      ></div>

                      <img src="./chat.png" style={{ width: 30 }} />

                      {/* {knowledgeType == 'Global' ? <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path d="M30.4631 20.1941C30.4631 18.2158 29.0834 16.5546 27.236 16.1205V15.9539C27.236 13.2509 25.0373 11.0523 22.3345 11.0523H16.9575V9.39338C18.2321 8.98698 19.1582 7.79237 19.1582 6.38415C19.1582 4.64245 17.7418 3.22603 16.0001 3.22603C14.2586 3.22603 12.8419 4.64243 12.8419 6.38415C12.8419 7.79237 13.7681 8.98701 15.0426 9.39338V11.0523H9.66562C6.96272 11.0523 4.76406 13.251 4.76406 15.9539V16.1205C2.91671 16.5546 1.53706 18.2159 1.53706 20.1941C1.53706 22.1785 2.92477 23.8433 4.78013 24.2716C4.98386 26.7885 7.09694 28.7741 9.66562 28.7741H22.3345C24.9032 28.7741 27.0161 26.7885 27.2199 24.2716C29.0753 23.8433 30.4631 22.1785 30.4631 20.1941ZM4.76406 18.1372V22.251C3.98967 21.8891 3.45218 21.1029 3.45218 20.194C3.45218 19.2853 3.98965 18.4992 4.76406 18.1372ZM14.7569 6.38415C14.7569 5.69888 15.3147 5.14103 16.0001 5.14103C16.6853 5.14103 17.2432 5.69892 17.2432 6.38415C17.2432 7.06937 16.6853 7.62726 16.0001 7.62726C15.3147 7.62726 14.7569 7.06934 14.7569 6.38415ZM25.3211 23.8726C25.3211 25.5192 23.9811 26.859 22.3345 26.859H9.66562C8.01895 26.859 6.67906 25.5192 6.67906 23.8726V15.9539C6.67906 14.3073 8.01895 12.9674 9.66562 12.9674H22.3345C23.9811 12.9674 25.3211 14.3073 25.3211 15.9539V23.8726ZM28.5479 20.1941C28.5479 21.1029 28.0104 21.8892 27.236 22.251V18.1373C28.0104 18.4992 28.5479 19.2854 28.5479 20.1941Z" fill="#3E6127" stroke="#3E6127" strokeWidth="0.4" strokeLinejoin="round" />
                    <mask id="path-2-inside-1_30_4067" fill="white">
                      <path d="M21.5246 16.0244C20.2924 16.0244 19.29 17.0269 19.29 18.259C19.29 19.4913 20.2925 20.4937 21.5246 20.4937C22.7568 20.4937 23.7593 19.4912 23.7593 18.259C23.7593 17.027 22.7568 16.0244 21.5246 16.0244ZM21.5246 18.9786C21.1278 18.9786 20.805 18.656 20.805 18.2591C20.805 17.8623 21.1278 17.5394 21.5246 17.5394C21.9215 17.5394 22.2443 17.8623 22.2443 18.2591C22.2443 18.6559 21.9215 18.9786 21.5246 18.9786Z" />
                    </mask>
                    <path d="M21.5246 14.0244C19.1877 14.0244 17.29 15.9225 17.29 18.259H21.29C21.29 18.1314 21.3971 18.0244 21.5246 18.0244V14.0244ZM17.29 18.259C17.29 20.5958 19.1879 22.4937 21.5246 22.4937V18.4937C21.397 18.4937 21.29 18.3867 21.29 18.259H17.29ZM21.5246 22.4937C23.8613 22.4937 25.7593 20.5958 25.7593 18.259H21.7593C21.7593 18.3866 21.6523 18.4937 21.5246 18.4937V22.4937ZM25.7593 18.259C25.7593 15.9224 23.8613 14.0244 21.5246 14.0244V18.0244C21.6523 18.0244 21.7593 18.1316 21.7593 18.259H25.7593ZM21.5246 16.9786C22.2319 16.9786 22.805 17.5509 22.805 18.2591H18.805C18.805 19.7611 20.0238 20.9786 21.5246 20.9786V16.9786ZM22.805 18.2591C22.805 18.9664 22.2328 19.5394 21.5246 19.5394V15.5394C20.0228 15.5394 18.805 16.7582 18.805 18.2591H22.805ZM21.5246 19.5394C20.8167 19.5394 20.2443 18.9666 20.2443 18.2591H24.2443C24.2443 16.7579 23.0263 15.5394 21.5246 15.5394V19.5394ZM20.2443 18.2591C20.2443 17.5508 20.8175 16.9786 21.5246 16.9786V20.9786C23.0255 20.9786 24.2443 19.7611 24.2443 18.2591H20.2443Z" fill="#3E6127" mask="url(#path-2-inside-1_30_4067)" />
                    <mask id="path-4-inside-2_30_4067" fill="white">
                      <path d="M10.4751 16.0244C9.24284 16.0244 8.24048 17.0269 8.24048 18.259C8.24048 19.4913 9.24288 20.4937 10.4751 20.4937C11.7072 20.4937 12.7098 19.4912 12.7098 18.259C12.7098 17.027 11.7072 16.0244 10.4751 16.0244ZM10.4751 18.9786C10.0783 18.9786 9.75548 18.656 9.75548 18.2591C9.75548 17.8623 10.0783 17.5394 10.4751 17.5394C10.8719 17.5394 11.1948 17.8623 11.1948 18.2591C11.1948 18.6559 10.8719 18.9786 10.4751 18.9786Z" />
                    </mask>
                    <path d="M12.7098 18.259H10.7098H12.7098ZM11.1948 18.2591H9.19476H11.1948ZM10.4751 14.0244C8.1382 14.0244 6.24048 15.9224 6.24048 18.259H10.2405C10.2405 18.1314 10.3475 18.0244 10.4751 18.0244V14.0244ZM6.24048 18.259C6.24048 20.5958 8.13831 22.4937 10.4751 22.4937V18.4937C10.3474 18.4937 10.2405 18.3867 10.2405 18.259H6.24048ZM10.4751 22.4937C12.8118 22.4937 14.7098 20.5958 14.7098 18.259H10.7098C10.7098 18.3866 10.6027 18.4937 10.4751 18.4937V22.4937ZM14.7098 18.259C14.7098 15.9224 12.8118 14.0244 10.4751 14.0244V18.0244C10.6027 18.0244 10.7098 18.1315 10.7098 18.259H14.7098ZM10.4751 16.9786C11.1822 16.9786 11.7555 17.5508 11.7555 18.2591H7.75548C7.75548 19.7612 8.9744 20.9786 10.4751 20.9786V16.9786ZM11.7555 18.2591C11.7555 18.9666 11.1832 19.5394 10.4751 19.5394V15.5394C8.97346 15.5394 7.75548 16.758 7.75548 18.2591H11.7555ZM10.4751 19.5394C9.76709 19.5394 9.19476 18.9666 9.19476 18.2591H13.1948C13.1948 16.7579 11.9767 15.5394 10.4751 15.5394V19.5394ZM9.19476 18.2591C9.19476 17.5508 9.76789 16.9786 10.4751 16.9786V20.9786C11.9759 20.9786 13.1948 19.7611 13.1948 18.2591H9.19476Z" fill="#3E6127" mask="url(#path-4-inside-2_30_4067)" />
                    <mask id="path-6-inside-3_30_4067" fill="white">
                      <path d="M18.0641 22.8489H13.9358C13.5174 22.8489 13.1782 23.188 13.1782 23.6062C13.1782 24.0247 13.5174 24.3638 13.9358 24.3638H18.0642C18.4825 24.3638 18.8217 24.0246 18.8217 23.6062C18.8217 23.188 18.4825 22.8489 18.0641 22.8489Z" />
                    </mask>
                    <path d="M18.8217 23.6062H20.8217V23.606L18.8217 23.6062ZM18.0641 20.8489H13.9358V24.8489H18.0641V20.8489ZM13.9358 20.8489C12.4132 20.8489 11.1782 22.0831 11.1782 23.6062H15.1782C15.1782 24.2929 14.6216 24.8489 13.9358 24.8489V20.8489ZM11.1782 23.6062C11.1782 25.1295 12.413 26.3638 13.9358 26.3638V22.3638C14.6217 22.3638 15.1782 22.9199 15.1782 23.6062H11.1782ZM13.9358 26.3638H18.0642V22.3638H13.9358V26.3638ZM18.0642 26.3638C19.5871 26.3638 20.8217 25.1292 20.8217 23.6062H16.8217C16.8217 22.92 17.3779 22.3638 18.0642 22.3638V26.3638ZM20.8217 23.606C20.8216 22.083 19.5867 20.8489 18.0641 20.8489V24.8489C17.3783 24.8489 16.8218 24.2929 16.8217 23.6064L20.8217 23.606Z" fill="#3E6127" mask="url(#path-6-inside-3_30_4067)" />
                  </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <path d="M30.4631 20.1939C30.4631 18.2156 29.0834 16.5545 27.236 16.1204V15.9537C27.236 13.2508 25.0373 11.0522 22.3345 11.0522H16.9575V9.39326C18.2321 8.98686 19.1582 7.79225 19.1582 6.38402C19.1582 4.64233 17.7418 3.2259 16.0001 3.2259C14.2586 3.2259 12.8419 4.64231 12.8419 6.38402C12.8419 7.79224 13.7681 8.98689 15.0426 9.39326V11.0522H9.66562C6.96272 11.0522 4.76406 13.2508 4.76406 15.9537V16.1204C2.91671 16.5545 1.53706 18.2157 1.53706 20.1939C1.53706 22.1784 2.92477 23.8431 4.78013 24.2715C4.98386 26.7884 7.09694 28.774 9.66562 28.774H22.3345C24.9032 28.774 27.0161 26.7884 27.2199 24.2715C29.0753 23.8431 30.4631 22.1784 30.4631 20.1939ZM4.76406 18.1371V22.2509C3.98967 21.889 3.45218 21.1028 3.45218 20.1939C3.45218 19.2852 3.98965 18.4991 4.76406 18.1371ZM14.7569 6.38402C14.7569 5.69875 15.3147 5.1409 16.0001 5.1409C16.6853 5.1409 17.2432 5.6988 17.2432 6.38402C17.2432 7.06925 16.6853 7.62714 16.0001 7.62714C15.3147 7.62714 14.7569 7.06922 14.7569 6.38402ZM25.3211 23.8725C25.3211 25.5191 23.9811 26.8589 22.3345 26.8589H9.66562C8.01895 26.8589 6.67906 25.5191 6.67906 23.8725V15.9537C6.67906 14.3072 8.01895 12.9673 9.66562 12.9673H22.3345C23.9811 12.9673 25.3211 14.3072 25.3211 15.9537V23.8725ZM28.5479 20.1939C28.5479 21.1028 28.0104 21.889 27.236 22.2509V18.1372C28.0104 18.4991 28.5479 19.2853 28.5479 20.1939Z" fill="#C11574" stroke="#C11574" strokeWidth="0.4" strokeLinejoin="round" />
                      <mask id="path-2-inside-1_135_5699" fill="white">
                        <path d="M21.5246 16.0244C20.2924 16.0244 19.29 17.0269 19.29 18.259C19.29 19.4913 20.2925 20.4937 21.5246 20.4937C22.7568 20.4937 23.7593 19.4912 23.7593 18.259C23.7593 17.027 22.7568 16.0244 21.5246 16.0244ZM21.5246 18.9786C21.1278 18.9786 20.805 18.656 20.805 18.2591C20.805 17.8623 21.1278 17.5394 21.5246 17.5394C21.9215 17.5394 22.2443 17.8623 22.2443 18.2591C22.2443 18.6559 21.9215 18.9786 21.5246 18.9786Z" />
                      </mask>
                      <path d="M21.5246 14.0244C19.1877 14.0244 17.29 15.9225 17.29 18.259H21.29C21.29 18.1314 21.3971 18.0244 21.5246 18.0244V14.0244ZM17.29 18.259C17.29 20.5958 19.1879 22.4937 21.5246 22.4937V18.4937C21.397 18.4937 21.29 18.3867 21.29 18.259H17.29ZM21.5246 22.4937C23.8613 22.4937 25.7593 20.5958 25.7593 18.259H21.7593C21.7593 18.3866 21.6523 18.4937 21.5246 18.4937V22.4937ZM25.7593 18.259C25.7593 15.9224 23.8613 14.0244 21.5246 14.0244V18.0244C21.6523 18.0244 21.7593 18.1316 21.7593 18.259H25.7593ZM21.5246 16.9786C22.2319 16.9786 22.805 17.5509 22.805 18.2591H18.805C18.805 19.7611 20.0238 20.9786 21.5246 20.9786V16.9786ZM22.805 18.2591C22.805 18.9664 22.2328 19.5394 21.5246 19.5394V15.5394C20.0228 15.5394 18.805 16.7582 18.805 18.2591H22.805ZM21.5246 19.5394C20.8167 19.5394 20.2443 18.9666 20.2443 18.2591H24.2443C24.2443 16.7579 23.0263 15.5394 21.5246 15.5394V19.5394ZM20.2443 18.2591C20.2443 17.5508 20.8175 16.9786 21.5246 16.9786V20.9786C23.0255 20.9786 24.2443 19.7611 24.2443 18.2591H20.2443Z" fill="#C11574" mask="url(#path-2-inside-1_135_5699)" />
                      <mask id="path-4-inside-2_135_5699" fill="white">
                        <path d="M10.4751 16.0244C9.24284 16.0244 8.24048 17.0269 8.24048 18.259C8.24048 19.4913 9.24288 20.4937 10.4751 20.4937C11.7072 20.4937 12.7098 19.4912 12.7098 18.259C12.7098 17.027 11.7072 16.0244 10.4751 16.0244ZM10.4751 18.9786C10.0783 18.9786 9.75548 18.656 9.75548 18.2591C9.75548 17.8623 10.0783 17.5394 10.4751 17.5394C10.8719 17.5394 11.1948 17.8623 11.1948 18.2591C11.1948 18.6559 10.8719 18.9786 10.4751 18.9786Z" />
                      </mask>
                      <path d="M12.7098 18.259H10.7098H12.7098ZM11.1948 18.2591H9.19476H11.1948ZM10.4751 14.0244C8.1382 14.0244 6.24048 15.9224 6.24048 18.259H10.2405C10.2405 18.1314 10.3475 18.0244 10.4751 18.0244V14.0244ZM6.24048 18.259C6.24048 20.5958 8.13831 22.4937 10.4751 22.4937V18.4937C10.3474 18.4937 10.2405 18.3867 10.2405 18.259H6.24048ZM10.4751 22.4937C12.8118 22.4937 14.7098 20.5958 14.7098 18.259H10.7098C10.7098 18.3866 10.6027 18.4937 10.4751 18.4937V22.4937ZM14.7098 18.259C14.7098 15.9224 12.8118 14.0244 10.4751 14.0244V18.0244C10.6027 18.0244 10.7098 18.1315 10.7098 18.259H14.7098ZM10.4751 16.9786C11.1822 16.9786 11.7555 17.5508 11.7555 18.2591H7.75548C7.75548 19.7612 8.9744 20.9786 10.4751 20.9786V16.9786ZM11.7555 18.2591C11.7555 18.9666 11.1832 19.5394 10.4751 19.5394V15.5394C8.97346 15.5394 7.75548 16.758 7.75548 18.2591H11.7555ZM10.4751 19.5394C9.76709 19.5394 9.19476 18.9666 9.19476 18.2591H13.1948C13.1948 16.7579 11.9767 15.5394 10.4751 15.5394V19.5394ZM9.19476 18.2591C9.19476 17.5508 9.76789 16.9786 10.4751 16.9786V20.9786C11.9759 20.9786 13.1948 19.7611 13.1948 18.2591H9.19476Z" fill="#C11574" mask="url(#path-4-inside-2_135_5699)" />
                      <mask id="path-6-inside-3_135_5699" fill="white">
                        <path d="M18.0641 22.8489H13.9358C13.5174 22.8489 13.1782 23.188 13.1782 23.6062C13.1782 24.0247 13.5174 24.3638 13.9358 24.3638H18.0642C18.4825 24.3638 18.8217 24.0246 18.8217 23.6062C18.8217 23.188 18.4825 22.8489 18.0641 22.8489Z" />
                      </mask>
                      <path d="M18.8217 23.6062H20.8217V23.606L18.8217 23.6062ZM18.0641 20.8489H13.9358V24.8489H18.0641V20.8489ZM13.9358 20.8489C12.4132 20.8489 11.1782 22.0831 11.1782 23.6062H15.1782C15.1782 24.2929 14.6216 24.8489 13.9358 24.8489V20.8489ZM11.1782 23.6062C11.1782 25.1295 12.413 26.3638 13.9358 26.3638V22.3638C14.6217 22.3638 15.1782 22.9199 15.1782 23.6062H11.1782ZM13.9358 26.3638H18.0642V22.3638H13.9358V26.3638ZM18.0642 26.3638C19.5871 26.3638 20.8217 25.1292 20.8217 23.6062H16.8217C16.8217 22.92 17.3779 22.3638 18.0642 22.3638V26.3638ZM20.8217 23.606C20.8216 22.083 19.5867 20.8489 18.0641 20.8489V24.8489C17.3783 24.8489 16.8218 24.2929 16.8217 23.6064L20.8217 23.606Z" fill="#C11574" mask="url(#path-6-inside-3_135_5699)" />
                    </svg>
                  }
                  <div style={{ width: 4.47, height: 4.47, left: 19.29, top: 16.02, position: 'absolute', border: '1px #3E6127 solid' }}></div>
                  <div style={{ width: 4.47, height: 4.47, left: 8.24, top: 16.02, position: 'absolute', border: '1px #3E6127 solid' }}></div>
                  <div style={{ width: 5.64, height: 1.51, left: 13.18, top: 22.85, position: 'absolute', border: '1px #3E6127 solid' }}></div>
                 */}
                    </div>
                    {/* <div style={{ width: 14, height: 14, left: 42, top: 42, position: 'absolute', background: '#12B76A', borderRadius: 7, border: '0.75px white solid' }} /> */}
                  </div>

                  <Box
                    style={{
                      backgroundColor: "#F2F4F7",
                      padding: 16,
                      borderRadius: "30px",
                      marginBottom: "10px",
                      marginTop: "2px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      height: "100%",
                      wordWrap: "break-word",
                      fontSize: "0.79rem",
                      color: "#101828",
                      fontWeight: "600",
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <div className="jumping-dots">
                      <span
                        className="jumping-dot"
                        style={{ background: "#1A719E" }}
                      ></span>
                      <span
                        className="jumping-dot"
                        style={{ background: "#1A719E" }}
                      ></span>
                      <span
                        className="jumping-dot"
                        style={{ background: "#1A719E" }}
                      ></span>
                    </div>
                  </Box>
                </div>
              ) : (
                <></>
              )}
              {showEmojiPicker && <Picker onEmojiClick={onEmojiClick} native />}
            </div>
          </Box>
          <div
            style={{
              position: "fixed",
              bottom: "80px",
              width: "80%",
              height: "180px",
            }}
          >
            <div class="plus">
              <div class="image-container">
                <div class="topic"></div>
              </div>
            </div>

            {/* <div>
              <p>Transcribed Text:</p>
              <p>{transcribedText}</p>
            </div> */}
            {/* {console.log(languageButton)} */}
            {/* <div style={{ marginLeft: "80px" }}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Select Language
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={languageButton}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="en-US"
                    control={<Radio />}
                    label="English"
                  />
                  <FormControlLabel
                    value="es-ES"
                    control={<Radio />}
                    label="Spanish"
                  />
                </RadioGroup>
              </FormControl>
            </div> */}

            <div className="querySelect">
              <Select
                sx={{
                  // border: '1px solid #ccc',
                  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                  borderRadius: "8px",
                  height: "40px",
                  // marginLeft: "76px",
                  // width: "87.6%",
                  "& .MuiSelect-select": {
                    // paddingTop: "6px",
                    fontSize: 16,
                    // width: "85.6%",
                  },
                  fontSize: "0.8rem",
                }}
                value={props.selectquery}
                onChange={(event) => {
                  props.setSelectquery(event.target.value);
                  handleInputChange(event);
                }}
                displayEmpty
                fullWidth
                variant="outlined"
              >
                <MenuItem sx={{ fontSize: "0.8rem" }} value="" disabled>
                  Select prompts
                </MenuItem>
                {query?.map((questions) => (
                  <MenuItem
                    sx={{ fontSize: "0.8rem" }}
                    key={questions}
                    value={questions}
                  >
                    {questions}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <ToastContainer />
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box sx={{ ...stylemodal, borderRadius: 3 }}>
                <div style={{ display: "grid" }}>
                  <div style={{ marginBottom: 10 }}>
                    <img
                      src="sad-face (1).png"
                      style={{
                        width: 25,
                        // marginLeft: 153,
                        marginBottom: "-23px",
                      }}
                    />{" "}
                    <p style={{ marginLeft: 40 }}>Why wasn't this helpful?</p>
                  </div>
                  <textarea
                    maxLength={800}
                    onChange={(e) => {
                      setVal(e.target.value);
                      // console.log(e.target.value, "asd");
                    }}
                  >
                    {val}
                  </textarea>
                  <div>
                    <div></div>
                    <div></div>
                  </div>

                  <p style={{ justifySelf: "flex-end" }}>
                    {characterCount}/{characterLimit}
                  </p>
                  {val === "" && (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "-13px",
                      }}
                    >
                      Please enter your feedback comment
                    </p>
                  )}

                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      border: "1px dashed #2397D4",
                      width: "420px",
                      color: "white",

                      background: "#C21B17",
                      width: "150px",
                      fontWeight: "600",
                      justifySelf: "center",
                      "&:hover": {
                        background: "#C21B17",
                      },
                    }}
                    style={{ marginTop: "" }}
                    onClick={(index) => {
                      handleClose();
                      // Feedbackdown();
                      Feedbackdown();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Box>
            </Modal>

            <Box
              component="footer"
              style={{ display: "flex" }}
              sx={{
                p: 2,

                // bgcolor: '#fbfbfb'
                // ,paddingLeft:"5%",paddingRight:"5%"
              }}
            >
              {" "}
              <div class="plus">
                <div class="image-container">
                  <img
                    src="./new-message.png"
                    alt="Your Image"
                    style={{ width: 40 }}
                    onClick={handleClear}
                  />

                  <div class="topic" onClick={handleClear}>
                    New Topic
                  </div>
                </div>
              </div>
              <Box
                style={{ width: "100%", marginLeft: "-70px" }}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 1,
                    // width: 120,
                    height: 50,
                  },
                }}
              >
                <Paper
                  style={{
                    width: "91%",
                    margin: "auto",
                    // boxShadow:
                    //   'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
                    boxShadow: "0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
                    borderRadius: 8,
                    paddingRight: "3px",
                    border: "0.50px #D0D5DD solid",
                    boxShadow: "none",
                    // marginLeft: -14,
                    height: 70,
                  }}
                  // elevation={3}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    style={{
                      height: "100%",
                      borderRadius: 100,
                      position: "relative",
                    }}
                  >
                    <Item
                      sx={{
                        boxShadow: "none",
                        p: 0,
                        marginLeft: 4,
                        background: "transparent",
                      }}
                    >
                      {/* emoji button */}
                      {/* <IconButton
                  sx={{ py: 0, px: 2 }}
                  onClick={() => {
                    setShowEmojiPicker(!showEmojiPicker);
                  }}
                >
                  <SentimentSatisfiedOutlinedIcon />
                </IconButton> */}
                    </Item>
                    <Item
                      style={{
                        boxShadow: "none",
                        width: "100%",
                      }}
                      sx={{ py: 0, px: 0, borderRadius: 100 }}
                    >
                      {/* <TextField
                  id="outlined-multiline-static"
                  multiline
                  maxRows={2}
                  style={{
                    width: '100%',
                    outline: 'none',
                    border: 'none',

                  }}
                  sx={{border:"none"}}

                  placeholder="Type your message here ..."
                  value={input_message}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
                /> */}

                      <textarea
                        type="text"
                        style={{
                          // marginLeft: "81px",
                          outline: "none",
                          border: "none",
                          padding: "0px",
                          overflow: "auto",
                          maxHeight: "100px",
                          fontSize: 16,
                          marginLeft: "7px",
                          width: "100%",
                          marginTop: "14px",
                        }}
                        maxLength={800}
                        placeholder="Type your message here ..."
                        value={input_message}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onFocus={(event) =>
                          (event.target.style.outline = "none")
                        }
                        onBlur={(event) => (event.target.style.outline = "")}
                      />
                      {/* <KeyboardVoiceIcon /> */}
                      {/* <p style={{ justifySelf: "flex-end" }}>
                        {characterCount}/{characterLimit}
                      </p> */}
                      {/* <Stack spacing={2} sx={{ width: 300 }}> */}
                      {/* <Autocomplete
                  value={input_message}
                  onChange={(val) => {
                    setInput_message(val);
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      border: "none",
                    },
                  }}
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={top100Films.map((option) => option.title)}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      // label="Search input"
                      placeholder="Ask any question"
                      value={input_message}
                      onChange={(event) => handleInputChange(event)}
                      onInput={(event) => handleInputChange(event)}
                      onKeyDown={handleKeyDown}
                      onFocus={(event) => (event.target.style.outline = "none")}
                      onBlur={(event) => (event.target.style.outline = "")}
                      InputProps={{
                        ...params.InputProps,
                        type: "input",
                        disableUnderline: true,
                      }}
                      // sx={{border:'none'}}
                      sx={{
                        "& .MuiInputBase-root": {
                          border: "none",
                        },
                      }}
                    />
                  )}
                /> */}
                      {/* </Stack> */}
                    </Item>

                    {/* <Item style={{ boxShadow: "none", p: 0 }}>
                      <IconButton
                        onClick={() => {
                          setVoice(!voice);
                        }}
                        style={{
                          // color: `${input_message ? "#7cc24d" : "disabled"}`,
                          padding: 0,
                        }}
                      >
                        <Avatar
                          sx={{
                            bgcolor: "#1A719E",
                            padding: 0,
                          }}
                        >
                          {" "}
                          {voice ? (
                            <MicOffIcon
                              onClick={() => {
                                // sttFromMic();
                                stopmic();
                              }}
                            />
                          ) : (
                            <KeyboardVoiceIcon
                              onClick={() => {
                                // setVoice(!voice);
                                // stopmic();
                                startRecording();
                              }}
                            />
                          )}
                        </Avatar>
                      </IconButton>
                    </Item> */}
                    <Item
                      style={{
                        boxShadow: "none",
                        background: "transparent",
                        border: "none",
                        padding: "",
                      }}
                    >
                      <IconButton
                        onClick={() => handleInputSubmit(input_message)}
                        disabled={!input_message}
                        style={{
                          color: `${input_message ? "#7cc24d" : "disabled"}`,
                          padding: 0,
                        }}
                      >
                        <Avatar
                          sx={{
                            bgcolor: "#1A719E",
                            padding: 0,
                          }}
                        >
                          {" "}
                          <SendIcon fontSize="8px" />
                        </Avatar>
                      </IconButton>
                    </Item>
                    <Item style={{ boxShadow: "none" }}></Item>
                  </Stack>
                </Paper>
              </Box>
            </Box>
          </div>
        </div>
        <div className="col-sm-1"></div>
      </div>

      {/* <Footer
        input_message={input_message}
        handleInputChange={handleInputChange}
        handleKeyDown={handleKeyDown}
      /> */}
    </>
  );
};

export default Point_in_Time_bot;
